import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import * as CONFIG from "../../../config";
import {
  MATCHED_UNMATCHED_QUESTIONS,
  OVERVIEW,
  REPORTS_QUIZZES,
  USAGETRENDS,
} from "../../../config";

export default function DashboardNavigation() {
  const { t } = useTranslation("translation");

  return (
    <nav className="nav">
      <NavLink activeClassName="active" to={OVERVIEW} className="nav-link">
        {t("Overview")}
      </NavLink>

      {/* <NavLink activeClassName="active" to={LEARNING} className="nav-link">
          {t("Learning")}
        </NavLink> */}

      <NavLink
        activeClassName="active"
        to={CONFIG.RESPONSES}
        className="nav-link"
      >
        {t("Responses")}
      </NavLink>

      <NavLink
        activeClassName="active"
        to={CONFIG.DISCUSSION}
        className="nav-link"
      >
        {t("Discussion")}
      </NavLink>

      <NavLink activeClassName="active" to={USAGETRENDS} className="nav-link">
        {t("Usage Trends")}
      </NavLink>

      <NavLink
        activeClassName="active"
        to={MATCHED_UNMATCHED_QUESTIONS}
        className="nav-link"
      >
        {t("Bot Experience")}
      </NavLink>

      <NavLink
        activeClassName="active"
        to={REPORTS_QUIZZES}
        className="nav-link"
      >
        {t("Quizzes")}
      </NavLink>
    </nav>
  );
}
