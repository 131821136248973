import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Button, Col, Image, ProgressBar, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import backArrow from "../../../assets/images/arrow-left.svg";
import closeimg from "../../../assets/images/close-red.svg";
import pptimg from "../../../assets/images/file-ppt.svg";

import DragAndDrop from "../../../components/UI/DragAndDrop";
import IconLabelButton from "../OldPortalComponents/IconLabelButton";
import InfoBlock from "../OldPortalComponents/InfoBlock";

import "./index.scss";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Loading from "../../../components/UI/Loading/Loading";
import { HelperService } from "../../../services/helper.service";
import { uploadFaqUrlAndDocument } from "../../../store/knowledge/actions";
import {
  getQuizDocumentsContents,
  saveQuizQuestionsBasedOnFile,
  updateQuiz as updateQuizAction,
} from "../../../store/quizzes/actions";

export const ImportQuiz = (props: any) => {
  let history = useHistory();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const useLocatio = useLocation();
  let re = /(?:\.([^.]+))?$/;
  const helper = new HelperService();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUploadType, setSelectedUploadType] =
    useState("customtemplates");
  const [documents, setDocuments] = React.useState([]);
  const [showError, setShowError] = useState({
    state: false,
    message: "Please enter a valid URL",
  });
  const [selectedFileName, setSelectedFileName] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  //   const useDidMountEffect = (func, deps) => {
  //     const didMount = useRef(false);
  useEffect(() => {
    setDocuments([]);
  }, []);

  const handleDocRemove = (e: React.MouseEvent<HTMLElement>, index: number) => {
    const newDocuments = [...documents];
    setDocuments(newDocuments);
  };

  const getFileDocumentsHandler = async (d: File[], type: any) => {
    setIsFileUploading(false);
    setSelectedFileType(type);

    setSelectedFileName(d[0]?.name);

    let totalDocuments = 0;
    totalDocuments = documents.length + d.length;
    if (totalDocuments > 1) {
      helper.showToaster(t("You can upload only single file."));
      return;
    }
    const oldDocuments = [...documents];
    await setDocuments([
      ...oldDocuments,
      ...d.map((doc: any) => {
        doc.sizeError = doc.size / (1024 * 1024) > 10;
        return doc;
      }),
    ]);
  };

  const uploadFile = async () => {
    if (documents.length === 0) {
      helper.showToaster(
        t("Please choose documents or add url before proceeding.")
      );
      return;
    }

    const formData = new FormData();
    formData.append("file", documents[0]);
    formData.append("filepath", documents[0].name);
    setIsLoading(true);

    try {
      const questionsBasedOnFileResponse: any = await dispatch(
        saveQuizQuestionsBasedOnFile(formData)
      );
      updateQuiz(questionsBasedOnFileResponse.questions);
    } catch (error) {
      helper.showToaster(t("Oops! Could not upload."));
    }
  };

  const savetoDatabase = async () => {
    if (documents.length === 0) {
      helper.showToaster(
        t("Please choose documents or add url before proceeding.")
      );
      return;
    } else {
      setIsFileUploading(true);

      const oldDocuments = [...documents];
      let document: any;
      const query = new URLSearchParams(useLocatio.search);
      const group = query.get("group");

      if (documents.length > 0) {
        document = oldDocuments[0];
        document.isUploading = true;
      }

      const uploadedDocument: any = await dispatch(
        uploadFaqUrlAndDocument(
          {
            file: documents ? documents[0] : null,
            file_name: documents[0].name,
            faqType: "quiz",
            group,
          },
          (event: any) => {
            if (documents.length > 0) {
              document.progress = Math.round(
                (100 * event.loaded) / event.total
              );
              setDocuments([...oldDocuments]);
            }
          }
        )
      );

      if (documents.length > 0) {
        if (uploadedDocument && uploadedDocument.data.status.code !== 200) {
          document.isUploaded = false;
          document.isError = true;
          document.isUploading = false;
          document.message = uploadedDocument.status.message;
          setDocuments([...oldDocuments]);
        } else {
          getQuizDocumentContent(uploadedDocument.data.data.document._id);
        }
      }
    }
  };

  const getQuizDocumentContent = async (documentId: any) => {
    try {
      const documentContent: any = await dispatch(
        getQuizDocumentsContents(documentId)
      );
      if (documentContent.document_content) {
        history.push(
          `/import-questions-bank/preview/${documentId}?through=create-quiz&quizId=${queryParams.get(
            "quizId"
          )}`,
          {
            quizId: queryParams.get("quizId"),
            selectedFileType: selectedFileType,
            selectedFile: selectedFile,
            selectedFileName: selectedFileName,
          }
        );
      } else {
        let quizArray: any[] = [];
        history.push(`/quizzes/edit/${props.location.state.quizId}`, {
          selectedFileType: selectedFileType === "customtemplates",
          documentContent: documentContent.document_content,
          quizArray,
          selectedFile: selectedFile,
          documentId: documentId,
          documentType: documentContent.document_type,
        });
      }
      return;
    } catch (error) {
      console.log("[ error ]", error);
    }
  };

  const updateQuiz = async (
    questions: any,
    content: any = null,
    documentId: any = null,
    documentType: any = null
  ) => {
    let quizArray: any = { questions: [] };

    questions.forEach(function (element: any) {
      let quiz = {
        ...element,
        id: element.id ? element.id : uuidv4(),
      };
      if (quiz.ideal_answer === null) {
        quiz.ideal_answer = "";
      }

      quizArray.questions.push({
        ...quiz,
        text: quiz.text.replaceAll("\n", "<br>"),
      });
    });

    if (selectedFileType === "customtemplates") {
      history.push("/quizzes/edit/" + props.location.state.quizId, {
        selectedFileType: selectedFileType === "customtemplates",
        documentContent: content,
        quizArray,
        selectedFile: selectedFile,
        documentId: documentId,
        documentType,
      });
      return;
    }

    try {
      await dispatch(updateQuizAction(queryParams.get("quizId"), quizArray));
      history.push("/quizzes/edit/" + queryParams.get("quizId"), {
        selectedFileType: selectedFileType === "customtemplates",
      });
    } catch (e) {}
  };

  const handleNextClick = () => {
    if (selectedFileType === "customtemplates") {
      savetoDatabase();
    } else {
      uploadFile();
    }
  };

  const handleRadioChange = (value: any) => {
    setSelectedUploadType(value);
    setSelectedFile("");
  };

  const RenderSelectedDocuments =
    documents &&
    documents.map((doc: any, index: number) => {
      const fileType = re.exec(doc.name)[1];
      let img = pptimg;

      return (
        <div className="files d-flex align-items-center" key={index}>
          <Image src={img} />
          <div className="mx-3">
            <strong className={doc.sizeError ? "f-error" : ""}>
              {doc.name}
            </strong>
            {!doc.sizeError && <span>{helper.formatBytes(doc.size)}</span>}
            {doc.sizeError && (
              <span className="f-error">
                {t("File not uploaded. File size is more than 10 MB.")}
              </span>
            )}
            <ProgressBar now={doc.progress} />
            {doc.isError && <span>{doc.message}</span>}
          </div>
          {!doc.isUploading && !isFileUploading && (
            <button
              className="btn ml-auto p-0"
              onClick={(e) => handleDocRemove(e, index)}
            >
              <Image src={closeimg} />
            </button>
          )}
        </div>
      );
    });

  if (isLoading) {
    return <Loading />;
  }

  return (
    // I want to use Noodle Factory template to upload quiz questions

    <section
      className={classNames({
        "page-mid-wraper": true,
        "h-without-foter": true,
      })}
    >
      <div>
        <Row>
          <Col md={12}>
            <button
              className="btn p-0 btn-back"
              onClick={() => {
                history.push("/beta/quizzes");
              }}
            >
              <img src={backArrow} alt="" />
              {t("Return to previous page")}
            </button>
            <h1 className="mb-2 d-flex align-items-center">
              {/* <button
                className="btn py-0"
                onClick={() => {
                  history.push("/beta/quizzes");
                }}
              >
                <img src={chevLeft} alt="" />
              </button> */}
              {t("Import Quiz")}
            </h1>
            <h2 className="mb-24 font-weight-normal">
              {t("Select the Quiz document to upload")}
            </h2>
          </Col>
        </Row>
        <div className="upload-document-page-container add-faq-container">
          <div className="page-content-container">
            {/* <Heading title="Import Quiz" /> */}

            {/* Formatted Upload Radio */}
            <div className="custom-control custom-radio mb-0">
              <input
                id="customtemplates-radio"
                type="radio"
                value="customtemplates"
                className="custom-control-input faq-option-radio"
                name="faq-upload-option"
                checked={selectedUploadType === "customtemplates"}
                onChange={(e) => handleRadioChange(e.target.value)}
              />
              <label
                className="custom-control-label font-weight-bold"
                htmlFor="formatted-radio"
              >
                {t(
                  "I want to upload a document with a list of questions and answers"
                )}
                <span className="beta-label">{t("Beta")}</span>
              </label>
            </div>
            <div className="radio-label-wrapper pl-4">
              {t(
                "Choose this if you have a list of quiz questions and answers ready in any format. The questions will be extracted from the uploaded document. You can edit them in the next screen."
              )}
            </div>

            {selectedUploadType !== "customtemplates" && (
              <div className="clearfix" />
            )}
            {selectedUploadType === "customtemplates" && (
              <React.Fragment>
                <InfoBlock>
                  {t(
                    "Here are some tips for you to clean your document before upload. This will help us to extract questions more accurately."
                  )}
                  <ul>
                    <li>
                      {t("It is recommended that the document consists of")}{" "}
                      <b>{t("only question and answer pairs.")}</b>{" "}
                      {t(
                        "Avoid any other details apart from question and answer pairs."
                      )}
                    </li>

                    <li>
                      {t("It is good if the")}{" "}
                      <b>
                        {t(
                          "question and answer pairs are separated by one or more empty/new lines."
                        )}
                      </b>{" "}
                      {t(
                        "Avoid adding empty/new lines within the same question and answer pairs."
                      )}
                    </li>

                    <li>
                      {t("We recommend that you")}{" "}
                      <b>{t("number the question and answer pairs.")}</b>{" "}
                      {t("Example, 1., 2., 3., or 1), 2), 3) and so on.")}
                    </li>

                    <li>
                      {t(
                        "If you have multiple choice questions, then it is good to"
                      )}{" "}
                      <b>{t("number the options")}</b>{" "}
                      {t(
                        "using alphabets like A., B., C. or a), b), c) and so on."
                      )}
                    </li>

                    <li>
                      {t(
                        "If you have paragraph type question and answer pairs, it is recommended to have answers starting with the 'Answer' keyword. Example,"
                      )}{" "}
                      <b>{t("Answer: This is the answer of the question.")}</b>
                    </li>
                  </ul>
                </InfoBlock>

                <div className="faq-upload-container mt-4 text-center mb-3">
                  <DragAndDrop
                    getFileDocumentsHandler={(documents: any[]) => {
                      if (!documents.length) {
                        return;
                      }
                      getFileDocumentsHandler(documents, "customtemplates");
                    }}
                    // supportedTypes={".xlsx"}
                    supportedTypes={".docx, .txt, .pdf"}
                    message={`<h2 className='mb-1'>Drop your files here or click to browse.</h2><div>Supported File types: .docx, .txt, .pdf</div>`}
                    maxFiles={1}
                    fileSizeMessage={showError ? showError.message : ""}
                    {...props}
                  />
                </div>

                <div className="justify-content-center text-center">
                  <div className="selected-files">
                    {RenderSelectedDocuments}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>

          <div className="page-content-container mt-4">
            {/* Unformatted Upload Radio */}
            <div className="custom-control custom-radio mb-0">
              <input
                id="nfaquiztemplates-radio"
                type="radio"
                value="nfaquiztemplates"
                className="custom-control-input faq-option-radio"
                name="faq-upload-option"
                checked={selectedUploadType === "nfaquiztemplates"}
                onChange={(e) => handleRadioChange(e.target.value)}
              />
              <label
                className="custom-control-label font-weight-bold"
                htmlFor="formatted-radio"
              >
                {t(
                  "I want to use Noodle Factory template to upload quiz questions"
                )}
              </label>
            </div>
            <div className="radio-label-wrapper pl-4">
              {t(
                "Choose this if you have a formatted list of quiz questions and answers in a document. You can download the"
              )}{" "}
              <IconLabelButton
                showIcon
                iconClass="icon-download"
                buttonLabel="sample quiz template"
                useDownload
                // fileSrc={SampleQuizTemplate}
                fileSrc={
                  "https://oldportal.staging.noodlefactory.ai/static/media/sample-quiz-template.79b29ea8.docx"
                }
              />
              {t(
                "and edit it before uploading. Note that the document must be in the given format."
              )}
            </div>

            {selectedUploadType === "nfaquiztemplates" && (
              <>
                <div className="faq-upload-container mt-4 text-center mb-3">
                  <DragAndDrop
                    getFileDocumentsHandler={(documents: any[]) => {
                      if (!documents.length) {
                        return;
                      }
                      getFileDocumentsHandler(documents, "nfaquiztemplates");
                    }}
                    // supportedTypes={".xlsx"}
                    supportedTypes={".docx, .txt"}
                    maxFiles={1}
                    message={`<h2 className='mb-1'>Drop your files here or click to browse.</h2><div>Supported File types: .docx, .txt</div>`}
                    fileSizeMessage={showError ? showError.message : ""}
                    {...props}
                  />
                </div>
                <div className="justify-content-center text-center">
                  <div className="selected-files">
                    {RenderSelectedDocuments}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="page-footer">
            <Button className="btn btn-primary" onClick={handleNextClick}>
              {t("Import Quiz")}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImportQuiz;
