import ApiUtil from '../utils/apiUtil'
import { getAgentFromStorage } from '../utils/appUtils'

type Type = any

type OrganisationPayload = {
  name: string
  usage_type: string
  default_language_code: string
  country: string
}

export default class AgentsService {
  async getCurrentOrganisation(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `/organisation/organisations/current`,
      method: 'GET',
    })
    return res
  }

  async updateCurrentOrganisation(data: OrganisationPayload): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `/organisation/organisations/current`,
      method: 'PUT',
      data,
    })
    return res
  }

  async getAllAgents(): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/agents?status=active`,
      method: 'GET',
    })
    return res
  }

  async updateSelectedAgent(data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `organisation/states`,
      method: 'PATCH',
      data: {
        ...data,
      },
    })
    return res
  }

  async updateAgentById(agentId: string, data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/agents/${agentId}?agent_id=${
        agentId ? agentId : getAgentFromStorage()
      }`,
      method: 'PUT',
      data: {
        ...data,
      },
    })
    return res
  }

  async deleteAgentById(agentId: string): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/agents/${agentId}?agent_id=${
        agentId ? agentId : getAgentFromStorage()
      }`,
      method: 'DELETE',
    })
    return res
  }

  async getAllOrganisationUsers(status: string[] = []): Promise<Type> {
    let url = `organisation/users?page_size=100000&agent_id=${getAgentFromStorage()}`

    if (status.length > 0) {
      url += '&status=' + status.join(',')
    }

    return await ApiUtil.SendAsync<any>({
      url: url,
      method: 'GET',
    })
  }

  async addNewAgent(data: any): Promise<Type> {
    const res = await ApiUtil.SendAsync<any>({
      url: `knowledge/agents`,
      method: 'POST',
      data: {
        ...data,
      },
    })
    return res
  }
}
