import jwt_decode from 'jwt-decode'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.scss'
import ProtectedRoute from './auth/ProtectedRoute'
import Footer from './components/Footer'
import AccessDenied from './components/UI/AccessDenied/AccessDenied'
import Loading from './components/UI/Loading/Loading'
import PasswordExpiryNotification from './components/UI/PasswordExpiryNotification/PasswordExpiryNotification'
import { appRoutes } from './config/app.routes'
import { GlobalContext } from './context/GlobalContext'
import ChooseAgent from './routes/ChooseAgent/ChooseAgent'
import { getAllAgents } from './store/agents/actions'
import { processAuthState, resetState } from './store/auth/actions'
import { IsLoggedIn } from './store/auth/selector'
import {
  getAllOrganisations,
  getOrganizationCurrentUserDetails,
  getOrganizationStates,
} from './store/common/actions'
import history from './utils/history'
import { useAuth0 } from './utils/react-auth0-wrapper'
import MainLayout from './components/MainLayout/MainLayout'
import { USER_REGISTRATION } from './config'
import AppInstance from './axios-global'
import DataApiInstance from './axios-data-instance'

const UserRegistration = React.lazy(() => import('./routes/UserRegistration'))
const TeachersToolkit = React.lazy(() => import('./routes/TeachersToolkit'))
const App: React.FC = () => {
  const { isAuthenticated, loading, getIdTokenClaims } = useAuth0()
  const dispatch = useDispatch()
  const loggedIn = useSelector(IsLoggedIn)
  const [loadingFromApp, setLoadingFromApp] = useState(false)
  const [isOneAuthFlowStarted, setOneAuthFlowStarted] = useState(false)
  const [currentOrganisation, setCurrentOrganisation] = useState(null)
  const [userFlowExecution, setUserFlowExecution] = useState(false)
  const [accessDeniedMode, setAccessDeniedMode] = useState(false)
  const [activateChooseAgentScreen, setActivateChooseAgentScreen] =
    useState(false)
  const [allAgents, setAllAgents] = useState([])
  const [passwordExpiryNotification, setPasswordExpiryNotification] = useState({
    open: false,
    message: '',
  })
  const [showChangeOrganisation, setShowChangeOrganisation] = useState(false)
  const [allOrganisations, setAllOrganisations] = useState([])

  const [selectedAgentName, setSelectedAgentName] = useState(
    localStorage.getItem('selectedAgentName'),
  )
  const [selectedAgentEmail, setSelectedAgentEmail] = useState(
    localStorage.getItem('selectedAgentEmail'),
  )

  const [isCanvasAuthenticated, setIsCanvasAuthenticated] = useState(false)

  // state used while exporting questions
  const [exportedQuestions, setExportQuestions] = useState(null)

  const windowObj: any = window
  /**
   *
   *
   */
  const getOrgStates = async () => {
    // if (isAuthenticated || loggedIn) {
    await dispatch(getOrganizationStates())
    await Promise.all([
      setOneAuthFlowStarted(true), // used for loading purpose only nothing to do with business logic,
      dispatch(getAllAgents()),
    ])
      .then(async allAgents => {
        const agents: any = allAgents[1]
        await setAllAgents(agents)
        if (!agents || agents.length === 0) {
          await setActivateChooseAgentScreen(true)
          await setOneAuthFlowStarted(false)
          return
        }
        return Promise.all([dispatch(getOrganizationStates())])
          .then(async (organization: any) => {
            const stateRecord: any = organization[0]
            setSelectedAgentName(stateRecord.selectedAgentName || '')
            setSelectedAgentEmail(stateRecord.selectedAgentName || '')
            dispatch(getOrganizationCurrentUserDetails())
            setUserFlowExecution(
              stateRecord.hasOwnProperty('setup_completed') ? true : false,
            )

            const hasAgent = agents.find(
              (agent: any) => agent._id === stateRecord.selectedAgent,
            )

            if (
              !stateRecord.hasOwnProperty('selectedAgent') ||
              !stateRecord.selectedAgent ||
              !hasAgent
            ) {
              setActivateChooseAgentScreen(true)
              console.log('[ APP in 1 ]')
            }
            setOneAuthFlowStarted(false)
            setLoadingFromApp(false)
          })
          .catch(error => {
            console.log('erorddd', error)
            setOneAuthFlowStarted(false)
          })
      })
      .catch(err => {
        console.log('eror', err)
        setOneAuthFlowStarted(false)
      })
    // }
  }

  useEffect(() => {
    const backUrl = localStorage.getItem('originalPath')
    dispatch(resetState())
    let code, state, error
    // Function to initiate one auth flow based on query params
    const processAuthFn = async (code: string) => {
      setOneAuthFlowStarted(true)
      const token: any = await dispatch(processAuthState(code))
      if (token) {
        await localStorage.setItem('token', token)
        await localStorage.removeItem('originalPath')
        await Promise.all([
          dispatch(getOrganizationStates()), // used for loading purpose only nothing to do with business logic,
          dispatch(getAllAgents()),
          dispatch(getAllOrganisations()),
        ])
          .then(async res => {
            const stateRecord: any = res[0]
            const agents: any = res[1]
            const orgs: any = res[2]
            await setAllAgents(agents)
            await setAllOrganisations(orgs.organisations || [])
            if (res && orgs.organisations && orgs.organisations.length > 1) {
              await setShowChangeOrganisation(true)
            }
            if (!agents || agents.length === 0) {
              await setActivateChooseAgentScreen(true)
            }

            setSelectedAgentName(stateRecord.selectedAgentName || '')
            setSelectedAgentEmail(stateRecord.selectedAgentName || '')
            dispatch(getOrganizationCurrentUserDetails())
            setUserFlowExecution(
              stateRecord.hasOwnProperty('setup_completed') ? true : false,
            )

            const hasAgent = agents.find(
              (agent: any) => agent._id === stateRecord.selectedAgent,
            )

            if (
              !stateRecord.hasOwnProperty('selectedAgent') ||
              !stateRecord.selectedAgent ||
              !hasAgent
            ) {
              setActivateChooseAgentScreen(true)
            }
          })
          .catch(err => {
            console.log('AUTH ERR', err)
          })
        history.push(backUrl)
        localStorage.removeItem('originalPath')
      }
      setOneAuthFlowStarted(false)
    }

    // If query params exist from one auth
    if (history.location && history.location.search) {
      const query = new URLSearchParams(history.location.search)
      code = query.get('code')
      state = query.get('state')
      error = query.get('error')
      if (windowObj.REACT_APP_SSO_PROVIDER === 'oneauth' && code && state) {
        if (!error) {
          // call authentication API for oneauth
          processAuthFn(code)
        }
        // Check for errors
        if (error && (error === 'unauthorized' || error === 'access_denied')) {
          setAccessDeniedMode(true)
        }
      }
      const token = query.get('t')
      if (token) {
        AppInstance.defaults.headers.common.Authorization = `Bearer ${token}`
        DataApiInstance.defaults.headers.common.Authorization = `Bearer ${token}`
        getOrgStates()
      }
    }

    if (isAuthenticated) {
      setOneAuthFlowStarted(true)
    }
  }, [])

  useEffect(() => {
    if (windowObj.REACT_APP_SSO_PROVIDER === 'oneauth') {
      return
    }
    if (!loadingFromApp) {
      setLoadingFromApp(isOneAuthFlowStarted || loading || loggedIn)
    }

    if (!isOneAuthFlowStarted && !loading && !loggedIn && loadingFromApp) {
      setLoadingFromApp(false)
    }

    return () => {}
  }, [isOneAuthFlowStarted, loading, loggedIn])

  useEffect(() => {
    if (isOneAuthFlowStarted) {
      return
    }

    const getOrganizationStatesFn = async () => {
      // if ( windowObj.REACT_APP_SSO_PROVIDER === 'oneauth' && loggedIn ) {
      //   setOneAuthFlowStarted(true); // used for loading purpose only nothing to do with business logic
      //   await dispatch(getOrganizationStates())
      //   setOneAuthFlowStarted(false);
      // }
      if (isAuthenticated || loggedIn) {
        await dispatch(getOrganizationStates())
        const getOrgList = async () => {
          const res: any = await dispatch(getAllOrganisations())
          setAllOrganisations(res.organisations || [])
          if (res && res.organisations && res.organisations.length > 1) {
            setShowChangeOrganisation(true)
          }
        }
        getOrgList()
        await Promise.all([
          setOneAuthFlowStarted(true), // used for loading purpose only nothing to do with business logic,
          dispatch(getAllAgents()),
        ])
          .then(async allAgents => {
            const agents: any = allAgents[1]
            await setAllAgents(agents)
            if (!agents || agents.length === 0) {
              await setActivateChooseAgentScreen(true)
              await setOneAuthFlowStarted(false)
              return
            }
            return Promise.all([dispatch(getOrganizationStates())])
              .then(async (organization: any) => {
                const stateRecord: any = organization[0]
                setSelectedAgentName(stateRecord.selectedAgentName || '')
                setSelectedAgentEmail(stateRecord.selectedAgentName || '')
                dispatch(getOrganizationCurrentUserDetails())
                setUserFlowExecution(
                  stateRecord.hasOwnProperty('setup_completed') ? true : false,
                )

                const hasAgent = agents.find(
                  (agent: any) => agent._id === stateRecord.selectedAgent,
                )

                if (
                  !stateRecord.hasOwnProperty('selectedAgent') ||
                  !stateRecord.selectedAgent ||
                  !hasAgent
                ) {
                  setActivateChooseAgentScreen(true)
                  console.log('[ APP in 1 ]')
                }
                if (windowObj.REACT_APP_SSO_PROVIDER === 'auth0') {
                  const claims: any = await getIdTokenClaims()
                  const id_token = claims.__raw
                  if (id_token) {
                    let decodedToken: any = jwt_decode(id_token)
                    if (
                      'https://noodlefactory.ai/password_expires_soon' in
                      decodedToken
                    ) {
                      if (
                        decodedToken[
                          'https://noodlefactory.ai/password_expires_soon'
                        ]
                      ) {
                        setPasswordExpiryNotification({
                          open: true,
                          message:
                            'Your password is expiring soon. Please reset your password from the login screen.',
                        })
                      }
                    }
                  }
                }

                setOneAuthFlowStarted(false)
              })
              .catch(error => {
                console.log('erorddd', error)
                setOneAuthFlowStarted(false)
              })
          })
          .catch(err => {
            console.log('eror', err)
            setOneAuthFlowStarted(false)
          })
      }
    }
    if (!isOneAuthFlowStarted) {
      getOrganizationStatesFn()
    }
  }, [isAuthenticated, loggedIn]) // auth0 and oneauth

  const agentSelectedHandler = (e: any) => {
    setActivateChooseAgentScreen(false)
  }

  // Access denied page
  if (accessDeniedMode) {
    return <AccessDenied />
  }

  // Loading screen
  if (loadingFromApp && windowObj.REACT_APP_SSO_PROVIDER !== 'oneauth') {
    console.log()
    return <Loading />
  }

  if (isOneAuthFlowStarted && windowObj.REACT_APP_SSO_PROVIDER === 'oneauth') {
    return <Loading />
  }

  // Choose agent screen
  if (activateChooseAgentScreen) {
    return (
      <GlobalContext.Provider
        value={{
          setSelectedAgentName,
          setSelectedAgentEmail,
        }}
      >
        <ChooseAgent
          allAgents={allAgents}
          agentSelected={agentSelectedHandler}
        />
      </GlobalContext.Provider>
    )
  }
  return (
    <>
      <GlobalContext.Provider
        value={{
          authoLoading: loading,
          loadingFromApp,
          setLoadingFromApp,
          accessDeniedMode,
          setAccessDeniedMode,
          isOneAuthFlowStarted,
          setUserFlowExecution,
          userFlowExecution,
          selectedAgentName,
          selectedAgentEmail,
          setSelectedAgentName,
          setSelectedAgentEmail,
          isCanvasAuthenticated,
          setIsCanvasAuthenticated,
          showChangeOrganisation,
          allOrganisations,
          currentOrganisation,
          setCurrentOrganisation,
          exportedQuestions,
          setExportQuestions,
        }}
      >
        <Router basename={windowObj.SUB_DOMAIN || '/admin'}>
          <React.Suspense fallback={<Loading />}>
            <Toaster containerStyle={{ top: '77px' }} />
            <PasswordExpiryNotification
              open={
                passwordExpiryNotification && passwordExpiryNotification.open
              }
              message={
                passwordExpiryNotification && passwordExpiryNotification.message
              }
            />
            <Switch>
              {!activateChooseAgentScreen &&
                !isOneAuthFlowStarted &&
                appRoutes.map((routes: any, index: number) => {
                  return (
                    <ProtectedRoute
                      key={'routing_index_' + index}
                      history={history}
                      exact
                      {...routes}
                    />
                  )
                })}
              {!activateChooseAgentScreen && (
                <ProtectedRoute
                  history={history}
                  exact
                  path="/"
                  // isHeader={userFlowExecution}
                  // component={userFlowExecution ? Dashboard : Welcome}
                  isHeader={true}
                  component={TeachersToolkit}
                />
              )}
              <Route
                exact
                path={USER_REGISTRATION}
                render={matchProps => (
                  <MainLayout showSidebar={false} showHeader={false}>
                    <UserRegistration history={history} {...matchProps} />
                  </MainLayout>
                )}
              />
            </Switch>
          </React.Suspense>
        </Router>
        <Footer />
      </GlobalContext.Provider>
    </>
  )
}

export default App
