export const URIUtil = {
  getChatBotLinkFromPortalURI: (uri: string, agent: string) => {
    return `${
      (window as any).CHATBOT_URL
    }/?agent=${agent}&through=admin-portal`;
    // return uri.includes("portalus")
    //   ? uri.includes("staging")
    //     ? "https://chatbotus.staging.noodlefactory.ai/?agent=" + agent + "&through=admin-portal"
    //     : "https://chatbotus.noodlefactory.ai/?agent=" + agent + "&through=admin-portal"
    //   : uri.includes("staging")
    //   ? "https://chatbot.staging.noodlefactory.ai/?agent=" + agent + "&through=admin-portal"
    //   : "https://chatbot.noodlefactory.ai/?agent=" + agent + "&through=admin-portal";
  },
};
