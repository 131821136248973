import { HelperService } from './../../services/helper.service'
import AgentsService from '../../services/AgentsService'
import { t } from 'i18next'
import KnowledgeService from '../../services/KnowledgeService'

type AppDispatchType = any
const agentsService = new AgentsService()
const helperService = new HelperService()
const knowledgeService = new KnowledgeService()

export const getSelectedAgent =
  (agentId: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await knowledgeService.getAgentDetails(agentId)
      return Promise.resolve(response.data.data.agent)
    } catch (error: any) {
      helperService.showToaster('Something went wrong while fetching agent.')
      return Promise.reject(false)
    }
  }

export const getAllAgents = () => async (dispatch: AppDispatchType) => {
  try {
    const response = await agentsService.getAllAgents()
    return Promise.resolve(response.data.data.agents)
  } catch (error: any) {
    return Promise.reject([])
  }
}

export const getCurrentOrganisation =
  () => async (dispatch: AppDispatchType) => {
    try {
      const response = await agentsService.getCurrentOrganisation()
      return Promise.resolve(response.data.data)
    } catch (error: any) {
      return Promise.reject([])
    }
  }

export const updateSelectedAgent =
  (data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await agentsService.updateSelectedAgent(data)
      if (data?.states?.showToster !== false) {
        helperService.showToaster(
          `You have selected agent "${response.data.data.states.selectedAgentName}"`,
          'success',
        )
      }
      return Promise.resolve(response.data.data.states)
    } catch (error: any) {
      helperService.showToaster('Something went wrong while switching agent.')
      return Promise.reject(false)
    }
  }

export const updateAgentById =
  (agentId: string, data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await agentsService.updateAgentById(agentId, data)
      return Promise.resolve(response.data.data.agent)
    } catch (error: any) {
      helperService.showToaster('Something went wrong while updating agent.')
      return Promise.reject(false)
    }
  }

export const deleteAgentById =
  (agentId: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await agentsService.deleteAgentById(agentId)
      helperService.showToaster('Agent deleted successfully.', 'success')
      return Promise.resolve(true)
    } catch (error: any) {
      helperService.showToaster('Something went wrong while deleting agent.')
      return Promise.reject(false)
    }
  }

export const getAllOrganisationUsers =
  () => async (dispatch: AppDispatchType) => {
    try {
      const response = await agentsService.getAllOrganisationUsers([
        'active',
        'inactive',
      ])
      return Promise.resolve(response.data.data)
    } catch (error: any) {
      return Promise.reject([])
    }
  }

export const addNewAgent = (data: any) => async (dispatch: AppDispatchType) => {
  try {
    const response = await agentsService.addNewAgent(data)
    helperService.showToaster(
      t('Agent has been successfully added.'),
      'success',
    )
    return Promise.resolve(response.data?.data?.agent)
  } catch (e: any) {
    helperService.showToaster(
      e.response &&
        e.response.data &&
        e.response.data.status &&
        e.response.data.status.message
        ? e.response.data.status.message
        : t('Something went wrong while creating agent.'),
    )
    return Promise.reject()
  }
}
