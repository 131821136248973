import { useTranslation } from "react-i18next";

import mpmLaptopImg from "./../../assets/New-images/MPM_pointing_at_laptop.png";
import "./styles.scss";

export const ChatImages = () => {
  const { t } = useTranslation("translation");
  return (
    <>
      <div className="ai-greatness-wrap px-4 pt-5 tw-mt-[100px]">
        <div>
          <h2 className="text-center ai-head-1 font-800">
            {t("You're")}
            <span className="clr-blue"> {t("on your way")}</span>
            {t(" to")}
            <span className="clr-pink"> {t("AI-Greatness!")}</span>
          </h2>
          <h3 className="text-center ai-head-2 font-800 tw-mt-3">
            {t("What you will enjoy in a few minutes:")}
          </h3>
        </div>
        <ul className="list-unstyled ai-greatness-btns mt-3">
          <li className="py-2">
            <button className="btn font-1 clr-pink font-800">
              {t("Seamless learning outcomes")}
            </button>
          </li>
          <li className="py-2">
            <button className="btn font-1 clr-pink font-800">
              {t("Effortless AI-generated assessments")}
            </button>
          </li>
          <li className="py-2">
            <button className="btn font-1 clr-pink font-800">
              {t("Document summarisation and refinement")}
            </button>
          </li>
          <li className="py-2">
            <button className="btn font-1 clr-pink font-800">
              {t("Instant topic FAQs and tutoring questions")}
            </button>
          </li>
          <li className="py-2">
            <button className="btn font-1 clr-pink font-800">
              {t("Auto-grading and personalised feedback")}
            </button>
          </li>
        </ul>
        <h3 className="text-center font-24 font-800 mt-4">
          {t("All with the click of a button!")}
        </h3>
      </div>
      <figure className="text-center mpm-laptop-img mt-auto mb-0">
        <img src={mpmLaptopImg} alt="" className="" />
      </figure>
    </>
  );
};

export default ChatImages;
