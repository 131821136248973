export const CheckIcon = ({ size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.86339 10.5834L3.08339 7.80336L2.13672 8.74336L5.86339 12.47L13.8634 4.47003L12.9234 3.53003L5.86339 10.5834Z"
        fill="#00864D"
      />
    </svg>
  );
};
