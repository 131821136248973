import React, {useState, useEffect} from "react";
import {Image} from "react-bootstrap";
import langImg from "./../../assets/images/lang.svg";
import downarrowblk from "./../../assets/images/dropdown-blue-carets.svg";
import i18n from "../../components/UI/Internationalisation/i18n";
import "./styles.scss";
import {LANGUAGE_OPTIONS} from "../../components/Header/ChangeLanguage";
import Select from "react-select";

export const LanguageSelect = (props: any) => {
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: "en",
    label: "English",
  });
  useEffect(() => {
    let defaultLang: any = {
      value: "en",
      label: "English",
    }
    if (props.default) {
      defaultLang = LANGUAGE_OPTIONS.find((l) => l.value === props.default);
      setSelectedLanguage(defaultLang)
    }
    return () => {
    }
  }, [props.default])

  return (
      <div className="lang-option d-flex">
        <Image src={langImg}/>
        <Select
            className="select-sort select-target select-blue shadow-none radius-2 border-0"
            classNamePrefix="select-target-prefix"
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => (
                  <img src={downarrowblk} alt="dropdown arrow"/>
              ),
            }}
            placeholder="Change Language"
            options={LANGUAGE_OPTIONS}
            onChange={(e: any) => {
              i18n.changeLanguage(e.value);
              setSelectedLanguage(e);
              props.setSelectedLanguageCode(e.value);
              localStorage.setItem('selectedLanguageCode', e.value)
            }}
            value={selectedLanguage}
        />
      </div>
  );
};

export default LanguageSelect;
