import { useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import i18n from "../../components/UI/Internationalisation/i18n";
import noodleLogo from "./../../assets/New-images/mobile-logo.png";
import LanguageSelect from "./LanguageSelect";
import Signup from "./Signup";
import "./styles.scss";
import Success from "./Success";
import VerifiedEmail from "./VerifiedEmail";

export const SignupIndex = (props: any) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [selectedLanguageCode, setSelectedLanguageCode] = useState(
    localStorage.getItem("selectedLanguageCode")
  );
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  // const [ subscriptionId, setSubscriptionId] = useState(null);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);
    const query = new URLSearchParams(history?.location?.search);
    const language =
      query.get("language") || localStorage.getItem("selectedLanguageCode");
    setSelectedLanguageCode(language);
    i18n.changeLanguage(language);
    localStorage.setItem("selectedLanguageCode", language);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const isMobile = windowSize[0] <= 767;

  return (
    <div className="position-relative">
      <div className="tw-hidden md:tw-flex language-header align-items-center justify-content-between">
        <img className="mob-header-logo d-md-none" src={noodleLogo} alt="" />
        <LanguageSelect
          setSelectedLanguageCode={setSelectedLanguageCode}
          default={selectedLanguageCode ? selectedLanguageCode : "en"}
        />
      </div>
      <Switch>
        <Route exact path={path}>
          <Signup
            isMobile={isMobile}
            selectedLanguageCode={selectedLanguageCode}
          />
        </Route>
        <Route path={`${path}/success`}>
          <Success />
        </Route>
        <Route path={`${path}/verified-email`}>
          <VerifiedEmail />
        </Route>
      </Switch>
    </div>
  );
};

export default SignupIndex;
