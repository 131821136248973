import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { LANGUAGE_OPTIONS } from '../../components/Header/ChangeLanguage'
import EmailInput from './EmailInput'
import Info from './Info'
import CommonService from '@/services/CommonService'
import { t } from 'i18next'
import { useLocation } from 'react-router'

const STEPS = {
  EMAIL: 'EMAIL',
  INFO: 'INFO',
  SEND_EMAIL: 'SEND_EMAIL',
}

const commonService = new CommonService()

// @ts-nocheck
const formValidationSchema: any = Yup.object().shape({
  first_name: Yup.string().required(''),
  last_name: Yup.string().required(''),
  country: Yup.string().required(''),
  email: Yup.string().email(t('Invalid email')).required(''),
  password: Yup.string()
    .required('')
    .test('isValidPw', t('Password is not valid'), val =>
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()_\-+=\[{\]}\\|;:'",<.>/?])[A-Za-z\d`~!@#$%^&*()_\-+=\[{\]}\\|;:'",<.>/?]{8,}$/.test(
        val,
      ),
    ),
  name: Yup.string().required(''),
  lang: Yup.string().required(''),
  signup_session: Yup.string(),
})

export const Signup = (props: any) => {
  // const history = useHistory();
  const [step, setStep] = useState(STEPS.EMAIL)
  const [openModalOrganisation, setOpenModalOrganisation] = useState(false)

  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const stepParams = query.get('step')
  const emailParams = query.get('email')

  const { isMobile } = props
  const form = useFormik({
    initialValues: {
      first_name: query.get('first_name') || '',
      last_name: query.get('last_name') || '',
      email: emailParams || '',
      password: '',
      name: '',
      // product_definition_id: plans[0].value,
      lang: query.get('language_code') || '',
      country: query.get('country') || '',
      signup_id: query.get('signup_id') || '',
      // subscription_id: "",
    },
    validationSchema: formValidationSchema,
    onSubmit: values => {},
  })

  const isEmailExisted = async () => {
    try {
      await commonService.isEmailExisted(emailParams)
      setOpenModalOrganisation(true)
    } catch (error) {
      window.location.href = '/signup'
      // history.push("signup");
      // helperService.showToaster("", "This email has already been registered");
    }
  }

  useEffect(() => {
    if (stepParams === 'config' && emailParams) {
      isEmailExisted()
    }
  }, [stepParams, emailParams])

  useEffect(() => {
    let selectedLanguage = LANGUAGE_OPTIONS.find(
      l => l.value === props.selectedLanguageCode,
    )
    if (selectedLanguage !== undefined) {
      form.setFieldValue('lang', selectedLanguage.value)
    }
  }, [props.selectedLanguageCode])

  return (
    <div className="sign-up-container">
      {step === STEPS.EMAIL && (
        <EmailInput
          value={form.values.email}
          onChange={(value: any) => form.setFieldValue('email', value)}
          form={form}
          gotoInfo={() => setStep(STEPS.INFO)}
          isMobile={isMobile}
        />
      )}
      {step === STEPS.INFO && (
        <Info
          form={{ ...form }}
          // setOpenModalOrganisation={setOpenModalOrganisation}
          selectedLanguageCode={props.selectedLanguageCode}
          back={() => {
            setStep(STEPS.EMAIL)
          }}
          isMobile={isMobile}
        />
      )}
    </div>
  )
}

export default Signup
