import { useEffect, useState } from "react";
import { Button, Image, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import Select from "react-select";
import downarrowblk from "../../../../assets/images/chevron-down-blk.svg";
import closeimg from "../../../../assets/images/close-blk.svg";
import PermissionWrapper from "../../../../components/UI/PermissionWrapper/PermissionWrapper";
import {
  addAdminUser,
  addLearnerToClasses,
  addUsersAndClassesToLearnerGroup,
} from "../../../../store/knowledge/actions";

import { useTranslation } from "react-i18next";

const AddUserToGroupByEmail = (props: any) => {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const { groupId }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedButtonTarget, setSelectedButtonTarget] = useState({
    email: "",
    first_name: "",
    last_name: "",
    group_ids: [],
  });

  useEffect(() => {
    if (!props.show) {
      return;
    }
    setSelectedButtonTarget({
      email: "",
      first_name: "",
      last_name: "",
      group_ids: [],
    });
  }, [props]);

  const createOrUpdateAgent = async () => {
    if (
      !validateEmail(selectedButtonTarget.email) ||
      !selectedButtonTarget.first_name ||
      !selectedButtonTarget.last_name ||
      !selectedButtonTarget.group_ids.length
    ) {
      return;
    }

    try {
      setLoading(true);
      const reponse: any = await dispatch(
        addAdminUser({
          email: selectedButtonTarget.email,
          first_name: selectedButtonTarget.first_name,
          last_name: selectedButtonTarget.last_name,
          role: "user",
        })
      );

      // For classes
      if (selectedButtonTarget.group_ids.length > 0) {
        for await (const userClass of selectedButtonTarget.group_ids) {
          await dispatch(
            addLearnerToClasses(userClass.value, reponse._id, "students")
          );
        }
      }

      // For groups
      await dispatch(
        addUsersAndClassesToLearnerGroup(groupId, {
          user_ids: [reponse._id],
        })
      );
      props.confirmHandler();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  //   const handleCreate = (inputValue: string) => {
  //     if (validateEmail(inputValue)) {
  //       const obj: any = {
  //         _id: null,
  //         label: inputValue,
  //         value: inputValue,
  //       };
  //       setCreatableValue(obj);
  //       setUsers([...users, obj]);
  //       setShowRestValues(true);
  //     }
  //     return;
  //   };

  const inputChangeHandler = (e: any) => {
    setSelectedButtonTarget({
      ...selectedButtonTarget,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-400"
      onHide={props.onHide}
    >
      <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
        <ModalTitle
          className="h5 py-1 font-600"
          id="contained-modal-title-vcenter"
        >
          {t("Add User")}
        </ModalTitle>
        <Button onClick={props.onHide} className="ml-auto">
          <Image src={closeimg} alt="" />
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="mb-12">
          <h3 className="mb-1">{t("Add a class into group")}</h3>
          <Select
            isSearchable={false}
            isMulti
            className="select-target radius-2"
            classNamePrefix="select-target-prefix"
            components={{
              DropdownIndicator: (props: any) => (
                <img src={downarrowblk} alt={t("dropdown arrow")} />
              ),
              IndicatorSeparator: () => null,
            }}
            onChange={(e: any) => {
              setSelectedButtonTarget({
                ...selectedButtonTarget,
                group_ids: e,
              });
            }}
            value={selectedButtonTarget.group_ids || []}
            placeholder="Classes"
            options={props.classes}
          />
        </div>

        <div className="mb-12">
          <h3 className="mb-1">{t("Add by user email")}</h3>
          <input
            type="text"
            className="form-control he-35 radius-2"
            placeholder={t("Please enter email")}
            name="email"
            value={selectedButtonTarget.email || ""}
            onChange={inputChangeHandler}
          />
        </div>

        <div className="mb-12">
          <h3 className="mb-1">{t("First Name")}</h3>

          <input
            type="text"
            className="form-control he-35 radius-2"
            placeholder={t("Please enter first name")}
            name="first_name"
            value={selectedButtonTarget.first_name || ""}
            onChange={inputChangeHandler}
          />
        </div>

        <div className="mb-12">
          <h3 className="mb-1">{t("Last Name")}</h3>

          <input
            type="text"
            className="form-control he-35 radius-2"
            placeholder={t("Please enter last name")}
            name="last_name"
            value={selectedButtonTarget.last_name || ""}
            onChange={inputChangeHandler}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <PermissionWrapper>
          <button
            disabled={loading}
            className="btn-outline-primary btn font-600 py-1"
            onClick={props.onHide}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn btn-primary ml-3 font-600 py-1"
            onClick={createOrUpdateAgent}
            disabled={loading}
          >
            {props.editMode ? t("Update") : t("Add")}
          </button>
        </PermissionWrapper>
      </ModalFooter>
    </Modal>
  );
};

export default AddUserToGroupByEmail;
