import { useEffect, useState } from 'react'
import { Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Loading from '../../components/UI/Loading/Loading'
import backArrow from './../../assets/images/arrow-back.svg'
import logoImg from './../../assets/images/Logo-Image.svg'
import noodleFactory from './../../assets/images/NoodleFactory.svg'

import { CheckIcon } from '@/components/icons/checkIcon'
import { DotIcon } from 'lucide-react'
import CommonService from '../../services/CommonService'
import history from '../../utils/history'
import ChatImages from './ChatImages'
import './styles.scss'

const fields = {
  email: {
    field: 'email',
    label: 'Email',
    isDisabled: true,
  },
  password: {
    field: 'password',
    label: 'Password',
    type: 'password',
  },
}

const commonService = new CommonService()

export const Info = (props: any) => {
  const { t } = useTranslation('translation')
  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState('')
  const [isAws, setIsAws] = useState(false)
  const { form, back, isMobile, setOpenModalOrganisation } = props

  const pwLengthValid =
    form.values.password.length >= 6 && form.values.password.length <= 20
  const pwUpperValid = /[A-Z]/.test(form.values.password)
  const pwLowerValid = /[a-z]/.test(form.values.password)
  const pwNumberValid = /[0-9]/.test(form.values.password)
  const pwSpecialValid = /[`~!@#$%^&*()_\-+=\[{\]}\\|;:'",<.>/?]+/.test(
    form.values.password,
  )

  const isPwValid =
    pwLengthValid &&
    pwUpperValid &&
    pwLowerValid &&
    pwNumberValid &&
    pwSpecialValid

  useEffect(() => {
    setApiError('')
    setIsAws(
      history.location &&
        history.location.pathname &&
        history.location.pathname.includes('aws-signup')
        ? true
        : false,
    )
  }, [])

  const renderInput = ({ field, label, isDisabled, type }: any) => {
    return (
      <>
        <div className="py-2">{t(label)}*</div>
        <input
          disabled={isDisabled}
          type={type === 'password' ? 'password' : 'text'}
          value={form.values[field]}
          className="form-control h-auto w-100 pt-2"
          onChange={(e: any) => {
            form.setFieldValue(field, e?.target?.value)
          }}
        />
      </>
    )
  }

  const register = async () => {
    try {
      const { email, password, country } = form.values
      setLoading(true)
      const res = await commonService.register(
        {
          email,
          password,
        },
        isAws,
      )
    } catch (error: any) {
    } finally {
      setLoading(false)
    }
  }

  if (loading) return <Loading />

  return (
    <div className="d-flex">
      <div className="green-bg d-flex flex-1 flex-column align-items-center justify-content-center max-col email-input-wrap">
        <div className="tw-flex tw-items-center top-logo tw-gap-1">
          <Image src={logoImg} />
          <Image src={noodleFactory} />
        </div>
        <div className="content-wrapper w-100">
          <div className="d-flex w-100">
            <Image
              className="back-btn"
              src={backArrow}
              onClick={() => {
                setApiError('')
                back()
              }}
            />
            <div
              className="pink-color-text"
              style={{ flex: 1, textAlign: 'center' }}
            >
              {t('Ready to be supercharged?')}
            </div>
          </div>
          <div className="input-wrapper">{renderInput(fields.email)}</div>
          <div className="input-wrapper">{renderInput(fields.password)}</div>
          <div className="tw-flex tw-flex-col tw-items-start tw-mt-2 tw-w-[80%] ">
            <div>
              {pwLengthValid ? <CheckIcon size={24} /> : <DotIcon />}
              {t('6 to 20 characters length')}
            </div>
            <div>
              {pwUpperValid ? <CheckIcon size={24} /> : <DotIcon />}
              {t('One uppercase character (A-Z)')}
            </div>
            <div>
              {pwLowerValid ? <CheckIcon size={24} /> : <DotIcon />}
              {t('One lowercase character (a-z)')}
            </div>
            <div>
              {pwNumberValid ? <CheckIcon size={24} /> : <DotIcon />}
              {t('One number (0-9)')}
            </div>
            <div>
              {pwSpecialValid ? <CheckIcon size={24} /> : <DotIcon />}
              {t('One special character (!@#$%&)')}
            </div>
          </div>
          <div className="btn-wrapper">
            <button
              className="btn btn-primary w-100"
              disabled={
                !form.values.email || !form.values.password || !isPwValid
              }
              onClick={() => {
                register()
              }}
            >
              {t('Create an account')}
            </button>
            <div className="p-1 clr-red">{apiError}</div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="d-flex flex-column align-items-center justify-content-center max-col">
          <ChatImages />
        </div>
      )}
    </div>
  )
}

export default Info
