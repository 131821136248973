import classNames from 'classnames'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Logo from '../../assets/New-images/logo.svg'
import * as CONFIG from '../../config'
import { useGlobalContext } from '../../context/GlobalContext'
import {
  getSelectedAgent,
  updateSelectedAgent,
} from '../../store/agents/actions'
import { getCommonState } from '../../store/common/selector'
import {
  Channels,
  Dashboard,
  Form,
  Knowledge,
  Maximize,
  Question,
  Quiz,
  Star,
  Users,
} from '../UI/Icons'
import { CustomLink } from './CustomLink'
import WhatsNewPopup from '@/routes/Welcome/WhatsNewPopup'
import CommonService from '@/services/CommonService'

const commonService = new CommonService()

interface SidebarItem {
  icon: ReactNode
  label: string
  link: string
}

const windowObj: any = window

interface SidebarProps {
  isMobile: boolean
  handleWhatsNewClick?: () => void
  showsWhatsNew?: boolean
}

export const Sidebar: FC<SidebarProps> = (props: SidebarProps) => {
  const { t } = useTranslation('translation')
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { setSelectedAgentName, setSelectedAgentEmail } = useGlobalContext()

  const handleAgentUpdate = async (agentId: string) => {
    try {
      const selectAgentReponse: any = await dispatch(getSelectedAgent(agentId))
      const updateAgentResponse: any = await dispatch(
        updateSelectedAgent({
          states: {
            selectedAgent: selectAgentReponse._id,
            selectedAgentName: selectAgentReponse.name,
            showToster: false,
          },
        }),
      )
      setSelectedAgentName(updateAgentResponse.selectedAgentName)
      setSelectedAgentEmail(updateAgentResponse.selectedAgentName)

      localStorage.setItem('selectedAgent', updateAgentResponse.selectedAgent)
      localStorage.setItem(
        'selectedAgentName',
        updateAgentResponse.selectedAgentName,
      )
      localStorage.setItem(
        'selectedAgentEmail',
        updateAgentResponse.selectedAgentName,
      )
      localStorage.setItem(
        'selectedLanguageCode',
        updateAgentResponse.selectedLanguageCode,
      )
    } catch (error) {
      console.error('Error updating agent:', error)
    }
  }

  useEffect(() => {
    const currentAgent = localStorage.getItem('selectedAgent')
    const searchParams = new URLSearchParams(location.search)
    const urlAgentId = searchParams.get('agent')

    if (urlAgentId && urlAgentId !== currentAgent) {
      handleAgentUpdate(urlAgentId)
    } else if (currentAgent && !searchParams.has('agent')) {
      searchParams.append('agent', currentAgent)
      history.replace({ search: searchParams.toString() })
    }
  }, [location, history])

  const categories: {
    category: string
    items: SidebarItem[]
  }[] = [
    {
      category: '#',
      items: [
        {
          icon: <Dashboard />,
          label: t('Dashboard'),
          link: '/dashboard',
        },
      ],
    },
    {
      category: t('Set Up'),
      items: [
        {
          icon: <Knowledge />,
          label: t('Knowledge'),
          link: '/knowledge/all',
        },
        {
          icon: <Quiz />,
          label: t('Exercises'),
          link: CONFIG.QUIZZES_BETA,
        },
        {
          icon: <Form />,
          label: t('Forms'),
          link: '/forms?viewMode=true',
        },
      ],
    },
    {
      category: t('Manage'),
      items: [
        {
          icon: <Channels />,
          label: t('Channels'),
          link: '/chat-customisation?viewMode=true',
        },
        {
          icon: <Users />,
          label: t('Users'),
          link: '/user-management?viewMode=true',
        },
      ],
    },
  ]

  return (
    <>
      <div className="tw-p-4 !tw-w-[200px] tw-bg-white tw-h-full flex">
        <div className="tw-flex tw-flex-col tw-gap-4 tw-h-full">
          <img src={Logo} alt="Logo" />

          <div className="tw-flex-1 tw-flex tw-flex-col tw-gap-4 tw-overflow-auto">
            {categories.map(({ category, items }) =>
              category === '#' ? (
                items.map((item, index) => <CustomLink key={index} {...item} />)
              ) : (
                <div className="tw-flex tw-flex-col tw-gap-1">
                  <div className="tw-text-[#79767A] tw-font-semibold tw-text-sm">
                    {category}
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-1">
                    {items.map((item, index) => (
                      <CustomLink key={index} {...item} />
                    ))}
                  </div>
                </div>
              ),
            )}
          </div>

          <div>
            <a
              className="tw-px-4 tw-py-2.5 tw-text-white tw-bg-primary tw-rounded-[4px] tw-flex tw-items-center tw-gap-4"
              href={`${windowObj.CHATBOT_URL}?agent=${localStorage.getItem(
                'selectedAgent',
              )}&through=admin-portal`}
              target="_blank"
              rel="noreferrer"
            >
              <Maximize />
              {t('Learner View')}
            </a>
          </div>

          <div className="tw-bg-black-20 tw-w-full tw-h-[1px]"></div>

          <div>
            <a
              className="tw-px-2 tw-py-4 tw-flex tw-items-center tw-gap-4  tw-font-bold tw-text-sm tw-font-mulish !tw-no-underline tw-text-grey"
              href="javascript:void(0)"
              onClick={() =>
                props?.handleWhatsNewClick ? props.handleWhatsNewClick() : null
              }
            >
              <Star /> {t("What's New")}
            </a>
            <CustomLink
              icon={<Question />}
              label={t('Support')}
              link={CONFIG.SUPPORT}
            />
          </div>
        </div>
      </div>
    </>
  )
}

interface CustomSidebarProps {
  isShown: boolean
  onToggle: () => void
}

const CustomSidebar: FC<CustomSidebarProps> = ({ isShown, onToggle }) => {
  const [showsWhatsNew, setShowsWhatsNew] = useState<boolean>(false)

  const getStates = async () => {
    const resp: any = await commonService.getUserStates()
    const { status, data } = resp
    if (status === 200) {
      if (data?.data?.states) {
        const states = data.data.states
        if (states?.showsWhatsNew === true) {
          setShowsWhatsNew(true)
          commonService.updateUserStates({
            states: {
              showsWhatsNew: false,
            },
          })
        }
      }
    }
  }

  const hideWhatsNewPopup = () => {
    setShowsWhatsNew(false)
  }

  const handleWhatsNewClick = () => {
    setShowsWhatsNew(true)
  }

  useEffect(() => {
    getStates()
  }, [])

  return (
    <>
      <div
        id="desktop-sidebar"
        className="max-lg:tw-hidden tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-shadow-sidebar"
      >
        <Sidebar isMobile={false} handleWhatsNewClick={handleWhatsNewClick} />
      </div>

      <div
        id="mobile-sidebar"
        className={classNames(
          'tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-overflow-hidden tw-shadow-sidebar tw-transition-all tw-duration-300',
          isShown ? 'tw-translate-x-0' : '-tw-translate-x-[200%]',
        )}
      >
        <Sidebar isMobile={true} handleWhatsNewClick={handleWhatsNewClick} />
      </div>

      {isShown && (
        <div
          className="tw-fixed tw-bg-black tw-bg-opacity-30 tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-z-[999]"
          onClick={() => onToggle()}
        ></div>
      )}

      <WhatsNewPopup show={showsWhatsNew} onHide={hideWhatsNewPopup} />
    </>
  )
}

export default CustomSidebar
