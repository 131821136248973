import CommonService from '../../services/CommonService'
import { HelperService } from '../../services/helper.service'
import { getAgentFromStorage } from '../../utils/appUtils'
import * as type from './actionType'
import { NotificationInterface } from './reducer'
type AppDispatchType = any

const commonService = new CommonService()
const helperService = new HelperService()

export const getOrganizationStates =
  () => async (dispatch: AppDispatchType, getState: any) => {
    try {
      dispatch({
        type: type.SETUISTATE,
        payload: {
          UILoading: true,
        },
      })
      const response = await commonService.getUserStates()

      localStorage.setItem(
        'selectedAgent',
        response.data.data.states.selectedAgent,
      )
      localStorage.setItem(
        'selectedAgentName',
        response.data.data.states.selectedAgentName,
      )
      if (response.data.data.states.selectedLanguageCode) {
        localStorage.setItem(
          'selectedLanguageCode',
          response.data.data.states.selectedLanguageCode,
        )
      }
      dispatch({
        type: type.SETCOMMONSTATE,
        payload: {
          ...response.data.data.states,
        },
      })
      dispatch({
        type: type.SETUISTATE,
        payload: {
          UILoading: false,
        },
      })
      return Promise.resolve(response.data.data.states)
    } catch (error: any) {
      localStorage.setItem('selectedAgent', null)
      localStorage.setItem('selectedAgentName', null)
      dispatch({
        type: type.SETUISTATE,
        payload: {
          UILoading: false,
        },
      })
      return Promise.resolve(false)
    }
  }

export const updateOrganizationFlow =
  (applicationStage: any) =>
  async (dispatch: AppDispatchType, getState: any) => {
    const { common } = getState()
    try {
      if (
        ((applicationStage.currentRoute === common.nextRoute ||
          !common.nextRoute ||
          applicationStage.currentRoute === '/knowledge/upload-documents' ||
          applicationStage.currentRoute === '/knowledge/upload-url-documents' ||
          applicationStage.currentRoute ===
            '/knowledge/knowledge-create-spreadsheet') &&
          applicationStage.currentRoute !== common.prevRoute) ||
        applicationStage.currentRoute.includes('generate-questions') ||
        applicationStage.currentRoute.includes('generate-answers') ||
        applicationStage.currentRoute.includes('generate-faq-responses')
      ) {
        const response = await commonService.updateUserStates({
          states: {
            ...applicationStage,
          },
        })
        dispatch({
          type: type.SETCOMMONSTATE,
          payload: {
            ...response.data.data.states,
          },
        })
      }
      return Promise.resolve(true)
    } catch (error: any) {
      helperService.showToaster(
        'Unable to update state please try again after sometime.',
      )
      return Promise.resolve(false)
    }
  }

export const forceUpdateOrganizationState =
  (applicationStage: any) =>
  async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await commonService.updateUserStates({
        states: {
          ...applicationStage,
        },
      })
      dispatch({
        type: type.SETCOMMONSTATE,
        payload: {
          ...response.data.data.states,
        },
      })
      return Promise.resolve(true)
    } catch (error: any) {
      return Promise.resolve(false)
    }
  }

export const getStickerSets =
  () => async (dispatch: AppDispatchType, getState: any) => {
    const { common } = getState()
    try {
      if (common && (!common.stickersets || common.stickersets.length === 0)) {
        const response = await commonService.getStickerSets()
        dispatch({
          type: type.STICKER_SETS_SUCCESS,
          payload: response.data.data.stickersets,
        })
      }
      return Promise.resolve(true)
    } catch (error: any) {
      dispatch({
        type: type.STICKER_SETS_FAIL,
        payload: [],
      })
      return Promise.resolve(false)
    }
  }

export const getStickersBasedOnNames =
  (name: string) => async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await commonService.getStickerFromName(name)
      dispatch({
        type: type.STICKERS_SUCCESS,
        payload: response.data.data.stickers,
      })
      return Promise.resolve(true)
    } catch (error: any) {
      dispatch({
        type: type.STICKERS_FAIL,
        payload: [],
      })
      return Promise.resolve(false)
    }
  }

export const getOrganizationCurrentUserDetails =
  (setAgent: boolean = true) =>
  async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await commonService.getOrganizationCurrentUserDetails(
        setAgent,
      )
      localStorage.setItem('selectedAgentEmail', response.data.data.user.email)
      dispatch({
        type: type.SETCOMMONSTATE,
        payload: {
          user: response.data.data.user,
        },
      })
      return Promise.resolve(response.data.data.user)
    } catch (error: any) {
      dispatch({
        type: type.SETCOMMONSTATE,
        payload: {
          user: {},
        },
      })
      return Promise.resolve(false)
    }
  }

export const getAllNotifications =
  () => async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await commonService.getAllNotificationsSummary()
      dispatch({
        type: type.ALLSIDEBARNOTIFICATIONS,
        payload: response.data.data,
      })
      return Promise.resolve(true)
    } catch (error: any) {
      dispatch({
        type: type.ALLSIDEBARNOTIFICATIONS,
        payload: {
          has_new_notification: false,
          notifications: [],
          unread_count: 0,
        },
      })
      return Promise.resolve(false)
    }
  }

export const turnOffNewNotificationsStatus =
  () => async (dispatch: AppDispatchType, getState: any) => {
    try {
      const { common } = getState()
      const response = await commonService.turnOffNewNotificationsStatus()
      dispatch({
        type: type.ALLSIDEBARNOTIFICATIONS,
        payload: {
          has_new_notification: false,
          notifications: common.notificationsSummary.notifications,
          unread_count: common.notificationsSummary.unread_count,
        },
      })
      return Promise.resolve(true)
    } catch (error: any) {
      return Promise.resolve(false)
    }
  }

export const readNotification =
  (id: string, isRead: any) =>
  async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await commonService.readNotification(id, isRead)
      dispatch({
        type: type.UPDATENOTIFICATIONS,
        payload: {
          id,
          isRead: isRead === 'true' ? false : true,
        },
      })
      return Promise.resolve(true)
    } catch (error: any) {
      helperService.showToaster(
        'Something went wrong while updating notification status.',
      )
      return Promise.resolve(false)
    }
  }

export const markAllAsRead =
  () => async (dispatch: AppDispatchType, getState: any) => {
    const { notifications } = getState().common.notificationsSummary

    for (let index = 0; index < notifications.length; index++) {
      const notification: NotificationInterface = notifications[index]
      try {
        await commonService.readNotification(notification._id, 'false')
        dispatch({
          type: type.UPDATENOTIFICATIONS,
          payload: {
            id: notification._id,
            isRead: true,
          },
        })
      } catch (error: any) {
        helperService.showToaster(
          'Something went wrong while updating notification status.',
        )
      }
    }
  }

export const deleteAllNotifications =
  () => async (dispatch: AppDispatchType, getState: any) => {
    const { notifications } = getState().common.notificationsSummary
    for await (const notification of notifications) {
      try {
        await commonService.deleteNotification(notification._id)
      } catch (error: any) {
        helperService.showToaster(
          'Something went wrong while deleting notification.',
        )
      }
    }
    return Promise.resolve(true)
  }

export const deleteNotification =
  (id: string) => async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await commonService.deleteNotification(id)
      dispatch({
        type: type.DELETENOTIFICATIONS,
        payload: id,
      })
      return Promise.resolve(true)
    } catch (error: any) {
      helperService.showToaster(
        'Something went wrong while deleting notification.',
      )
      return Promise.resolve(false)
    }
  }

export const getTestBotLink =
  () => async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await commonService.getChatBotLink()
      return Promise.resolve(
        response.data.data.public_chat_url +
          '&agent_id=' +
          getAgentFromStorage(),
      )
    } catch (error: any) {
      // helperService.showToaster('Something went wrong while fetching bot URL.')
      return Promise.resolve(false)
    }
  }

export const getUsersRegistrationData =
  (token: string, isIte: boolean = false) =>
  async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await commonService.getUsersRegistrationData(
        token,
        isIte,
      )
      return Promise.resolve(response.data.data)
    } catch (error: any) {
      return Promise.reject(error.response)
    }
  }

export const generatePassword =
  (email: string, password: string, token: string, isIte: boolean = false) =>
  async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await commonService.generatePassword(
        email,
        password,
        token,
        isIte,
      )
      return Promise.resolve(response.data.data)
    } catch (error: any) {
      return Promise.reject(error.response)
    }
  }

export const updateSelectedLanguage =
  (selectedLanguageCode: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await commonService.updateUserStates({
        states: {
          selectedLanguageCode: selectedLanguageCode,
        },
      })
      await localStorage.setItem('selectedLanguageCode', selectedLanguageCode)
      // helperService.showToaster(`You have selected agent "${response.data.data.states.selectedLanguageCode}"`, 'success');
      return Promise.resolve(response.data.data.states.selectedLanguageCode)
    } catch (error: any) {
      helperService.showToaster('Something went wrong while updating language.')
      return Promise.reject(false)
    }
  }

export const getAgentFeatures = () => async (dispatch: AppDispatchType) => {
  try {
    const response = await commonService.getAgentFeatures()
    return Promise.resolve(response.data.data.features)
  } catch (error: any) {
    // helperService.showToaster('Something went wrong while updating language.');
    return Promise.reject(null)
  }
}

export const setSelectedGroupId =
  (selectedGroupId: string) => (dispatch: AppDispatchType) => {
    dispatch({
      type: type.SELECT_GROUP_ID,
      payload: selectedGroupId,
    })
  }

export const setSelectedYear = (year: any) => (dispatch: AppDispatchType) => {
  dispatch({
    type: type.SELECT_YEAR,
    payload: year,
  })
}

export const getAllOrganisations = () => async () => {
  try {
    const response = await commonService.getAllOrganisations()
    return Promise.resolve(response.data.data)
  } catch (error: any) {
    return Promise.resolve(error)
  }
}

export const getOrganisationFeatures =
  () => async (dispatch: AppDispatchType) => {
    try {
      const response = await commonService.getOrganisationFeatures()
      return Promise.resolve(response.data.data.features)
    } catch (error: any) {
      return Promise.reject(null)
    }
  }

export const getPersonas = () => async (dispatch: AppDispatchType) => {
  try {
    const response = await commonService.getPersonas()
    return Promise.resolve(response.data.data.personas)
  } catch (error: any) {
    return Promise.reject(null)
  }
}
