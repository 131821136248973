import { t } from 'i18next'
import * as _ from 'lodash'
import { orderBy } from 'lodash'
import DashboardService from '../../services/DashboardService'
import KnowledgeService from '../../services/KnowledgeService'
import UploadFilesService from '../../services/UploadService'
import {
  convertArrayToMarkdownFormat,
  convertDataToMarkdownFormat,
  setupModuleFormat,
  transformNestedLevels,
  transformNestedModules,
} from '../../utils/appUtils'
import { IntentInterface } from '../conversation/reducer'
import { HelperService } from './../../services/helper.service'
import * as type from './actionType'

type AppDispatchType = any

const service = new KnowledgeService()
const uploadService = new UploadFilesService()
const helperService = new HelperService()
const dashboardService = new DashboardService()

export const getResponseErrorMessage = (
  response: any,
  defaultMessage: string = null,
) => {
  let _defaultMsg = defaultMessage || t('Something went wrong.')
  if (!response) {
    return _defaultMsg
  }
  return response?.data?.status?.message || _defaultMsg
}

export const resetDocuments = () => async (dispatch: AppDispatchType) => {
  dispatch({
    type: type.RESET_DOCUMENTS,
  })
}

export const updateAllDocuments =
  (documents: any) => async (dispatch: AppDispatchType) => {
    dispatch({
      type: type.UPDATE_ALL_DOCUMENTS,
      documents,
    })
  }

export const uploadFilesToStorage =
  (
    file: any,
    eventCallback: any,
    documentType: string = 'other',
    uploadOption: string = 'upload',
    knowledge_group_id: string = null,
    flow: string = 'v1',
  ) =>
  async (dispatch: AppDispatchType) => {
    try {
      if (uploadOption === 'newupload') {
        try {
          const response = await uploadService.createDocumentFlow2(
            file.name,
            documentType,
            knowledge_group_id,
            flow,
          )
          const documentResponse = await uploadService.uploadV2(
            file,
            response.data.data.document._id,
            (event: any) => eventCallback(event),
          )
          dispatch({
            type: type.STORE_DOCUMENTS,
            payload: documentResponse.data.data.document,
          })
          return {
            ...response.data,
            ...documentResponse.data.data.document,
          }
        } catch (error: any) {
          return error && error.response ? error.response.data : error
        }
      } else {
        try {
          const response = await uploadService.upload(file, (event: any) =>
            eventCallback(event),
          )
          const documentIdToDB = await uploadService.updateDocumentIdToDB({
            file_name: file.name,
            file_id: response.data.data.file._id,
            type: documentType,
          })
          dispatch({
            type: type.STORE_DOCUMENTS,
            payload: documentIdToDB.data.data.document,
          })
          return {
            ...response.data,
            _id: documentIdToDB.data.data.document._id,
          }
        } catch (error: any) {
          return error && error.response ? error.response.data : error
        }
      }
    } catch (error: any) {
      return error && error.response ? error.response.data : error
    }
  }

export const removeDocumentByDocumentId =
  (documentId: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await uploadService.removeDocumentByDocumentId(
        documentId,
      )
    } catch (error: any) {
      return error.response && error.response.data
        ? error.response.data
        : error.message
    }
  }

export const getKeywordsFromDocument =
  (userDocuments: any, document: any[]) =>
  async (dispatch: AppDispatchType) => {
    for (let index = 0; index < document.length; index++) {
      try {
        const hasDocument = userDocuments.find(
          (userDocument: any) => userDocument._id === document[index],
        )
        if (!hasDocument) {
          const documentResponse = await service.getDocumentFromID(
            document[index],
          )
          dispatch({
            type: type.STORE_DOCUMENTS,
            payload: documentResponse.data.data.document,
          })
        }
        dispatch({
          type: type.INIT_STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS,
          payload: {
            saveKnowledge: false,
            keywordsLoading: true,
            documentId: document[index],
          },
        })
        const response = await service.getKeywordsFromDocumentUsingOffset(
          document[index],
        )
        const qnas = response.data.data.qnas.map((question: any) => {
          return {
            ...question,
            isNew: true,
          }
        })

        dispatch({
          type: type.STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS,
          payload: {
            keywordsLoading: false,
            keywords: response.data.data.questions,
            keywordsQnas: _.orderBy(qnas, 'sort_order', 'asc'),
            retryExecution: response.data.data.questions.length === 0, // response.data.data.questions.length === 0,
            last_offset: response.data.data.last_offset,
            documentId: document[index],
          },
        })
      } catch (e: any) {
        dispatch({
          type: type.STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS,
          payload: {
            keywordsLoading: false,
            retryExecution: false,
            keywords: [],
            keywordsQnas: [],
            keywordFetchError: true,
            documentId: document[index],
          },
        })
        // console.log('[[ ERROR WHILE GENERATING KEYWORDS ]]', e)
        helperService.showToaster(
          t('Something went wrong while generating keyword.'),
        )
      }
    }

    dispatch({
      type: 'INIT_GENERATE_ANSWERS_SCREEN',
      payload: {
        keyWordsLoading: false,
      },
    })
  }

export const loadMoreKeywordForDocument =
  (document: any, offset: number = 0, limit: number = 10) =>
  async (dispatch: AppDispatchType) => {
    try {
      // dispatch({
      //     type: type.INIT_STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS,
      //     payload: {
      //         saveKnowledge: false,
      //         keywordsLoading: true,
      //         documentId: document._id
      //     },
      // });
      const response = await service.getKeywordsFromDocumentUsingOffset(
        document._id,
        offset,
        limit,
      )
      // dispatch({
      //     type: type.STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS,
      //     payload: {
      //         keywordsLoading: false,
      //         keywords: document.keywords.concat(response.data.data.questions),
      //         last_offset: response.data.data.last_offset,
      //         documentId: document._id
      //     }
      // });

      const qnas = response.data.data.qnas.map((question: any) => {
        return {
          ...question,
          isNew: true,
        }
      })

      return Promise.resolve({
        last_offset: response.data.data.last_offset,
        questionsLength: response.data.data.questions.length,
        keywordsLoading: false,
        keywords: response.data.data.questions,
        keywordsQnas: _.orderBy(qnas, 'sort_order', 'asc'),
        documentId: document._id,
        keywordFetchError: false,
      })
    } catch (e: any) {
      // dispatch({
      //     type: type.STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS,
      //     payload: {
      //         keywordsLoading: false,
      //         last_offset: 0,
      //         keywords: [],
      //         keywordFetchError: true,
      //         documentId: document._id
      //     }
      // });
      // console.log('e', e);
      helperService.showToaster(
        t('Something went wrong while loading more questions.'),
      )
      return Promise.reject({
        questionsLength: 0,
        keywordsLoading: false,
        last_offset: 0,
        keywords: [],
        keywordsQnas: [],
        keywordFetchError: true,
        documentId: document._id,
      })
    }
  }

export const updateDocumentAfterTimeout =
  (document: any) => async (dispatch: AppDispatchType, useState: any) => {
    const { knowledge } = useState()
    const fetchedDocument = knowledge.documents.find(
      (doc: any) => doc._id === document._id,
    )
    dispatch({
      type: type.UPDATE_DOCUMENT_AFTER_INTERVAL,
      payload: {
        keywordFetchError: fetchedDocument.keywords.length === 0,
        documentId: document._id,
      },
    })
  }

export const resetAnswersScreen = () => async (dispatch: AppDispatchType) => {
  dispatch({
    type: type.GENERATE_ANSWERS_RELOADED,
    payload: null,
  })
}

export const generateAnswersBasedOnKeywordsFromDocument =
  (userDocuments: any, document: any[]) =>
  async (dispatch: AppDispatchType) => {
    dispatch({
      type: type.INIT_GENERATE_ANSWERS_SCREEN,
      payload: {
        keyWordsLoading: true,
      },
    })
    for (let index = 0; index < document.length; index++) {
      try {
        const hasDocument = userDocuments.find(
          (userDocument: any) => userDocument._id === document[index],
        )
        let documentResponse
        let documentKeywordResponse
        if (!hasDocument) {
          documentResponse = await service.getDocumentFromID(document[index])
          documentKeywordResponse = await service.getKeywordsFromDocument(
            document[index],
          )
          await dispatch({
            type: type.STORE_DOCUMENTS,
            payload: documentResponse.data.data.document,
          })
          await dispatch({
            type: type.STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS,
            payload: {
              keywordsLoading: false,
              keywords: documentKeywordResponse.data.data.keywords,
              documentId: document[index],
            },
          })
        }

        await dispatch({
          type: type.INIT_STORE_QUESTIONS_FOR_KEYWORDS,
          payload: {
            documentId: document[index],
            loading: true,
          },
        })

        const documentKeyword = !hasDocument
          ? documentKeywordResponse.data.data.keywords
          : hasDocument.keywords
        for (
          let keywordIndex = 0;
          keywordIndex < documentKeyword.length;
          keywordIndex++
        ) {
          const response =
            await service.generateAnswersBasedOnKeywordsFromDocument(
              document[index],
              documentKeyword[keywordIndex],
            )
          dispatch({
            type: type.STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS,
            payload: {
              qas: response.data.data.qas,
              documentId: document[index],
            },
          })
        }
        dispatch({
          type: type.INIT_STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS,
          payload: {
            documentId: document[index],
            loading: false,
          },
        })
      } catch (error: any) {
        helperService.showToaster(
          t('Something went wrong while fetching answers.'),
        )
      }
    }
  }

export const saveResponsesToKnowledge =
  (userDocuments: any, shouldCreateButtons: boolean) =>
  async (dispatch: AppDispatchType) => {
    try {
      dispatch({
        type: type.INIT_SAVE_RESPONSE_TO_KNOWLEDGE,
      })
      let intentsArray: string[] = []
      for (const document of userDocuments) {
        const saveDataToKnowledge = []
        let buttonData = {}
        for await (const question of document.qas.reverse()) {
          saveDataToKnowledge.push({
            agent_id: document.agent_id,
            organisation_id: document.organisation_id,
            original_question: question.original_question,
            question: question.question,
            original_response: question.original_response,
            response: await convertDataToMarkdownFormat(question.response),
          })
          const intentResponse: any = await service.createNewIntent({
            name: question.question,
            questions: [{ text: question.question }],
            responses: await convertArrayToMarkdownFormat([
              { text: question.response },
            ]),
            buttons:
              shouldCreateButtons && Object.keys(buttonData).length
                ? [buttonData]
                : [],
            document_id: document._id,
            type: 3,
          })
          if (
            shouldCreateButtons &&
            intentResponse &&
            intentResponse.data.data &&
            intentResponse.data.data.intent
          ) {
            buttonData = {
              group_ids: null,
              label: intentResponse.data.data.intent.name,
              name: intentResponse.data.data.intent.name,
              reference: null,
              reference_id: intentResponse.data.data.intent._id,
              type: 'intent',
            }
          }
          if (intentResponse.data.data.intent._id) {
            intentsArray.push(intentResponse.data.data.intent._id)
          }
        }
        const documentResponse = await service.saveResponsesToKnowledge(
          document._id,
          saveDataToKnowledge,
        )
      }

      if (shouldCreateButtons && intentsArray.length) {
        service.generateAssessmentquiz({ intent_ids: intentsArray })
      }

      helperService.showToaster(
        t('Knowledge has been successfully created.'),
        'success',
      )

      // dispatch(updateOrganizationFlow({
      //     ...organizationFlowObject,
      //     prevRoute: `/knowledge/generate-questions${history.location.search}`,
      //     currentRoute: `${history.location.pathname}${history.location.search}`,
      //     nextRoute: '/knowledge/all',
      //     setup_completed: false
      // }));

      await dispatch({
        type: type.SAVE_RESPONSE_TO_KNOWLEDGE_SUCCESS,
      })
    } catch (error: any) {
      helperService.showToaster(
        t('Something went wrong while creating knowledge.'),
      )
      dispatch({
        type: type.SAVE_RESPONSE_TO_KNOWLEDGE_FAIL,
        payload: [],
      })
    }
  }

export const localUpdateUrlData =
  (data: any) => async (dispatch: AppDispatchType) => {
    dispatch({
      type: type.LOCAL_UPDATE_URL_DATA,
      payload: data,
    })
  }

export const paginateWebLinks =
  (index: number, pageNumber: number, pageSize: number) =>
  async (dispatch: AppDispatchType) => {
    dispatch({
      type: type.PAGINATE_WEB_LINKS,
      payload: {
        index,
        pageNumber,
        pageSize,
      },
    })
  }

export const generateWebLinksFromUrl =
  (validUrls: any[]) => async (dispatch: AppDispatchType) => {
    for (let index = 0; index < validUrls.length; index++) {
      const url = validUrls[index]
      try {
        dispatch({
          type: type.INIT_GENERATE_WEBLINKS_FROM_URL,
          payload: {
            index,
            loading: true,
            links: [],
            editMode: true,
          },
        })
        let domain = new URL(url.name)
        const formData = new FormData()
        formData.append('url', url.name)
        formData.append('domain', domain.hostname)
        const response = await service.generateWebLinksFromUrl(formData)
        const links = response.data.data.links
        // console.log('links', links)
        dispatch({
          type: type.INIT_GENERATE_WEBLINKS_FROM_URL,
          payload: {
            index,
            loading: false,
            links,
            editMode: false,
          },
        })
      } catch (error: any) {
        helperService.showToaster(
          `${t('Something went wrong while generating links from')} "${
            url.name
          }".`,
        )
        dispatch({
          type: type.INIT_GENERATE_WEBLINKS_FROM_URL,
          payload: {
            index,
            loading: false,
            links: [],
            editMode: false,
          },
        })
      }
    }
  }

export const storeUserSelectedWebLinks =
  (urls: string[]) => async (dispatch: AppDispatchType) => {
    dispatch({
      type: type.RESET_DOCUMENTS,
    })
    dispatch({
      type: type.INIT_STORE_WEBSITE_TO_SERVER,
    })
    const succeedUrls = []
    for (let index = 0; index < urls.length; index++) {
      try {
        const formData = new FormData()
        formData.append('url', urls[index])
        const response = await service.storeUserSelectedWebLinks(formData)
        const documentIdToDB = await uploadService.updateDocumentIdToDB({
          file_name: urls[index],
          file_id: response.data.data.file._id,
          type: 'other',
        })
        dispatch({
          type: type.STORE_DOCUMENTS,
          payload: documentIdToDB.data.data.document,
        })
        succeedUrls.push(documentIdToDB.data.data.document._id)
      } catch (error: any) {
        helperService.showToaster(
          `${t('Something went wrong while storing')} "${urls[index]}".`,
        )
      }
    }
    if (succeedUrls.length > 0) {
      dispatch({
        type: type.STORE_WEBSITE_TO_SERVER_SUCCESS,
      })
      return Promise.resolve(succeedUrls)
    } else {
      return Promise.reject(false)
    }
  }

export const getAllKnowledge =
  (
    types: number,
    page: number,
    page_size: number,
    q: string,
    topicIds: string = '',
    sorting: {
      sort_field?: string
      sort_order?: string
    },
    hasCategoryFilterApplied?: boolean,
    documentId?: string,
    knowledge_group_ids?: string,
    hasTopLevelApplied?: boolean,
    isArchived?: boolean,
    isActiveArchived?: boolean,
  ) =>
  async (dispatch: AppDispatchType, getState: any) => {
    try {
      dispatch({
        type: type.INIT_ALL_KNOWLEDGE,
      })
      const allKnowledge = await service.getAllKnowledge(
        types,
        page,
        page_size,
        q,
        topicIds,
        sorting,
        null,
        hasCategoryFilterApplied,
        documentId,
        knowledge_group_ids,
        hasTopLevelApplied,
        isArchived,
        isActiveArchived,
      )
      dispatch({
        type: type.FETCH_ALL_KNOWLEDGE_SUCCESS,
        payload: allKnowledge.data.data,
      })
    } catch (error: any) {
      // helperService.showToaster('Something went wrong while storing websites.')
      // console.log('error', error.response, 'error.request', error.request, 'error.message', error.message);
      dispatch({
        type: type.FETCH_ALL_KNOWLEDGE_ERROR,
      })
      // return error.response && error.response.data ? error.response.data : error.message;
    }
  }

export const getAllTopics = () => async (dispatch: AppDispatchType) => {
  try {
    dispatch({
      type: type.FETCH_TOPICS_SUCCESS,
      payload: {
        topicsLoading: true,
        topics: [],
      },
    })
    const allTopics = await service.getAllTopics()
    dispatch({
      type: type.FETCH_TOPICS_SUCCESS,
      payload: {
        topicsLoading: false,
        topics: allTopics.data.data.topics,
      },
    })
  } catch (error: any) {
    // helperService.showToaster('Something went wrong while storing websites.')
    // console.log('error', error.response, 'error.request', error.request, 'error.message', error.message);
    dispatch({
      type: type.FETCH_TOPICS_ERROR,
      payload: {
        topicsLoading: false,
        topics: [],
      },
    })
    // return error.response && error.response.data ? error.response.data : error.message;
  }
}

export const updateKnowledgeById =
  (
    knowledgeId: string,
    data: {
      subtopic_id?: string
      topic_id?: string
      topic_name?: string
    },
  ) =>
  async (dispatch: AppDispatchType) => {
    try {
      dispatch({
        type: type.INIT_KNOWLEDGE_BY_ID,
      })
      const response = await service.updateKnowledgeById(knowledgeId, data)
      helperService.showToaster(t('Updated successfully.'), 'success')
      dispatch({
        type: type.UPDATE_KNOWLEDGE_BY_ID_SUCCESS,
        payload: {
          ...response.data.data.intent,
          topic_name: data.topic_name,
        },
      })
    } catch (error: any) {
      helperService.showToaster(
        'Something went wrong while updating knowledge.',
      )
      dispatch({
        type: type.UPDATE_KNOWLEDGE_BY_ID_ERROR,
      })
    }
  }

export const suggestKnowledgeById =
  (knowledgeId: string, is_suggested: boolean) =>
  async (dispatch: AppDispatchType) => {
    try {
      dispatch({
        type: type.INIT_KNOWLEDGE_BY_ID,
      })
      const response = await service.suggestKnowledgeById(
        knowledgeId,
        is_suggested,
      )
      helperService.showToaster(t('Updated successfully.'), 'success')
      dispatch({
        type: type.UPDATE_KNOWLEDGE_BY_ID_SUCCESS,
        payload: response.data.data.intent,
      })
    } catch (error: any) {
      helperService.showToaster(
        t('Something went wrong while updating knowledge.'),
      )
      dispatch({
        type: type.UPDATE_KNOWLEDGE_BY_ID_ERROR,
      })
    }
  }

export const bulkSuggestKnowledgeByIds =
  (knowledgeIds: string[]) => async (dispatch: AppDispatchType) => {
    try {
      dispatch({
        type: type.INIT_KNOWLEDGE_BY_ID,
      })
      for await (const knowledgeId of knowledgeIds) {
        const response = await service.suggestKnowledgeById(knowledgeId, true)
        dispatch({
          type: type.UPDATE_BULK_KNOWLEDGE_BY_ID_SUCCESS,
          payload: response.data.data.intent,
        })
      }
      dispatch({
        type: type.CHUNK_KNOWLEDGE_FINISHED,
      })
      helperService.showToaster(t('Updated successfully.'), 'success')
    } catch (error: any) {
      helperService.showToaster(
        t('Something went wrong while updating knowledge.'),
      )
      dispatch({
        type: type.UPDATE_KNOWLEDGE_BY_ID_ERROR,
      })
    }
  }

export const downloadKnowledgeAsCSV =
  (type: number, document_id: string | null = null, extras?: any) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.downloadKnowledgeAsCSV(
        type,
        document_id,
        extras,
      )
      helperService.ConvertToCSV(response.data, 'bot_knowledge')
    } catch (error: any) {
      helperService.showToaster('Something went wrong while downloading csv.')
    }
  }

export const deleteKnowledgeById =
  (intentIds: string[], isArchiving: boolean) =>
  async (dispatch: AppDispatchType) => {
    dispatch({
      type: type.CHUNK_KNOWLEDGE_STARTED,
    })
    for await (const intentId of intentIds) {
      await service
        .deleteKnowledgeById(intentId, isArchiving)
        .then((result: any) => {
          dispatch({
            type: type.DELETE_KNOWLEDGE_BY_ID_SUCCESS,
            payload: {
              intentId,
            },
          })
        })
        .catch((err: any) => {
          helperService.showToaster(
            t('Something went wrong while deleting knowledge.'),
          )
        })
    }
    dispatch({
      type: type.CHUNK_KNOWLEDGE_FINISHED,
    })
    return Promise.resolve(true)
  }

export const restoreKnowledgeById =
  (intentIds: string[]) => async (dispatch: AppDispatchType) => {
    dispatch({
      type: type.CHUNK_KNOWLEDGE_STARTED,
    })
    for await (const intentId of intentIds) {
      await service
        .restoreKnowledgeById(intentId)
        .then((result: any) => {
          dispatch({
            type: type.RESTORE_KNOWLEDGE_BY_ID_SUCCESS,
            payload: {
              intentId,
            },
          })
        })
        .catch((err: any) => {
          helperService.showToaster(
            t('Something went wrong while restoring knowledge.'),
          )
        })
    }
    dispatch({
      type: type.CHUNK_KNOWLEDGE_FINISHED,
    })
    return Promise.resolve(true)
  }

export const getFaqResponsesFromDocument =
  (userDocuments: any, document: any[]) =>
  async (dispatch: AppDispatchType) => {
    for (let index = 0; index < document.length; index++) {
      dispatch({
        type: type.STORE_FAQ_RESPONSES_FOR_DOCUMENT_SUCCESS,
        payload: {
          qas: [],
          documentId: document[index],
          keyWordsLoading: false,
        },
      })
      return
      try {
        const hasDocument = userDocuments.find(
          (userDocument: any) => userDocument._id === document[index],
        )
        if (!hasDocument) {
          const documentResponse = await service.getDocumentFromID(
            document[index],
          )
          dispatch({
            type: type.STORE_DOCUMENTS,
            payload: documentResponse.data.data.document,
          })
        }
        dispatch({
          type: type.INIT_STORE_FAQ_RESPONSES_FOR_DOCUMENT,
          payload: {
            saveKnowledge: false,
            keywordsLoading: true,
            documentId: document[index],
          },
        })
        const response = await service.getFaqResponsesFromDocument(
          document[index],
        )
        dispatch({
          type: type.STORE_FAQ_RESPONSES_FOR_DOCUMENT_SUCCESS,
          payload: {
            qas: response.data.data.qas,
            documentId: document[index],
            keyWordsLoading: false,
          },
        })
      } catch (e: any) {
        // console.log('[[ ERROR WHILE GENERATING KEYWORDS ]]', e)
        dispatch({
          type: type.STORE_FAQ_RESPONSES_FOR_DOCUMENT_SUCCESS,
          payload: {
            qas: [],
            documentId: document[index],
            keyWordsLoading: false,
          },
        })
        // helperService.showToaster(
        //   "Something went wrong while generating responses."
        // );
      }
    }
  }

export const updateFaqResponsesFromDocument =
  (documents: any[]) => async (dispatch: AppDispatchType) => {
    for (let index = 0; index < documents.length; index++) {
      try {
        dispatch({
          type: type.INIT_STORE_FAQ_RESPONSES_FOR_DOCUMENT,
          payload: {
            saveKnowledge: false,
            keywordsLoading: true,
            documentId: documents[index]._id,
          },
        })
        for await (const document of documents[index].qas) {
          if (document.questions && document.questions.length > 0) {
            await service.createNewIntent({
              name: document.questions[0].text,
              questions: document.questions,
              responses: await convertArrayToMarkdownFormat(document.responses),
              buttons: document.buttons,
              document_id: documents[index]._id,
              type: 3,
            })
          }
        }
        helperService.showToaster(
          t('Questions are added to the knowledge successfully.'),
          'success',
        )
        return Promise.resolve(true)
      } catch (e: any) {
        // console.log('e', e);
        helperService.showToaster(
          'Something went wrong while updating responses.',
        )
        return Promise.reject()
      }
    }
  }

export const updateSuggestionsOrderingLocally =
  (intents: IntentInterface[]) =>
  async (dispatch: AppDispatchType, getState: any) => {
    const { knowledge } = getState()
    dispatch({
      type: type.FETCH_ALL_SUGGESTIONS_SUCCESS,
      payload: {
        intents: intents,
        pagination: knowledge.allSuggestions.pagination,
      },
    })
  }

export const getAllSuggestions =
  (
    types: number,
    sorting: {
      sort_field?: string
      sort_order?: string
      page_size?: number
    },
    isSuggested: boolean | null,
  ) =>
  async (dispatch: AppDispatchType, getState: any) => {
    try {
      dispatch({
        type: type.INIT_ALL_KNOWLEDGE,
      })
      const allKnowledge = await service.getAllSuggestions(
        types,
        sorting,
        isSuggested,
      )
      dispatch({
        type: type.FETCH_ALL_SUGGESTIONS_SUCCESS,
        payload: allKnowledge.data.data,
      })
    } catch (error: any) {
      dispatch({
        type: type.FETCH_ALL_SUGGESTIONS_ERROR,
      })
    }
  }

export const updateSuggestionsOrdering =
  (
    intents: {
      intent_id?: string
      is_suggested?: boolean
      order?: number
    }[],
  ) =>
  async (dispatch: AppDispatchType, getState: any) => {
    try {
      dispatch({
        type: type.INIT_ALL_KNOWLEDGE,
      })
      const allKnowledge = await service.updateSuggestedIntents(intents)
      dispatch({
        type: type.FETCH_ALL_SUGGESTIONS_SUCCESS,
        payload: allKnowledge.data.data,
      })
    } catch (error: any) {
      dispatch({
        type: type.FETCH_ALL_SUGGESTIONS_ERROR,
      })
    }
  }

export const deleteSuggestion =
  (knowledgeId: string, is_suggested: boolean) =>
  async (dispatch: AppDispatchType) => {
    try {
      dispatch({
        type: type.INIT_KNOWLEDGE_BY_ID,
      })
      const response = await service.suggestKnowledgeById(
        knowledgeId,
        is_suggested,
      )
      helperService.showToaster(
        t('Successfully remove from suggestions.'),
        'success',
      )
      dispatch({
        type: type.UPDATE_SUGGESTION_BY_ID,
        payload: response.data.data.intent,
      })
    } catch (error: any) {
      helperService.showToaster(
        t('Something went wrong while removing intent from suggestion.'),
      )
      return Promise.reject(false)
    }
  }

export const uploadFaqUrlAndDocument =
  (
    {
      file,
      file_name,
      faqType,
      group,
      flow = 'v1',
    }: {
      file: File
      file_name: string
      faqType: string
      group: string
      flow?: string
    },
    eventCallback: any,
    isSkipShowToast = false,
  ) =>
  async (dispatch: AppDispatchType) => {
    try {
      const createFaqDocument = await uploadService.createFaqDocument({
        name: file_name,
        type: faqType,
        knowledge_group_id: group,
        flow: flow ? flow : 'v1',
      })
      const uploadFaqDocumentUsingDocumentId =
        await uploadService.uploadFaqDocumentUsingDocumentId(
          {
            file_name: file_name,
            file: file,
          },
          createFaqDocument.data.data.document._id,
          eventCallback,
        )
      // console.log('uploadFaqDocumentUsingDocumentId', uploadFaqDocumentUsingDocumentId);
      dispatch({
        type: type.STORE_DOCUMENTS,
        payload: uploadFaqDocumentUsingDocumentId.data.data.document,
      })
      return Promise.resolve({
        ...uploadFaqDocumentUsingDocumentId,
        document: uploadFaqDocumentUsingDocumentId.data.data.document,
      })
    } catch (error: any) {
      !isSkipShowToast &&
        helperService.showToaster(
          faqType === 'quiz'
            ? t('Something went wrong while generating document.')
            : t('Something went wrong while generating faq.'),
        )
      return Promise.reject(false)
    }
  }

export const bulkUploadLearners =
  (file: any, eventCallback: any) => async (dispatch: AppDispatchType) => {
    try {
      const uploadFaqDocumentUsingDocumentId =
        await uploadService.bulkUploadLearners(file, eventCallback)
      return Promise.resolve(uploadFaqDocumentUsingDocumentId.data.data.users)
    } catch (error: any) {
      helperService.showToaster(t('Something went wrong while uploading file.'))
      return Promise.reject([])
    }
  }

/**
 * Action is used to fetch questions based on document / URL faqs
 * @param documentId: string
 * @returns
 */
export const generateDocumentFaqs =
  (documentId: string, dispatchActionOrNot: boolean = true) =>
  async (dispatch: AppDispatchType) => {
    try {
      if (dispatchActionOrNot) {
        dispatch({
          type: type.DOCUMENT_FAQ_INIT,
          payload: {
            loading: true,
            data: [],
            document: null,
          },
        })
      }
      const documentResponse = await service.getDocumentFromID(documentId)
      const response = await service.generateDocumentFaqs(documentId)
      const questions: any = []
      await response.data.data.qnas.map((question: any, index: number) => {
        questions.push({
          questions: [
            {
              text: question.question,
            },
          ],
          responses: [
            {
              text: question.answer,
            },
          ],
        })
      })
      const payload = {
        loading: false,
        data: questions,
        document: documentResponse.data.data.document,
      }
      if (dispatchActionOrNot) {
        // console.log()
        dispatch({
          type: type.DOCUMENT_FAQ_INIT,
          payload: payload,
        })
      }
      return Promise.resolve(payload)
    } catch (e: any) {
      if (dispatchActionOrNot) {
        dispatch({
          type: type.DOCUMENT_FAQ_INIT,
          payload: {
            loading: false,
            data: [],
            document: null,
          },
        })
      }
      helperService.showToaster(
        t('Something went wrong while generating faqs.'),
      )
      return Promise.reject()
    }
  }

/**
 * Update document FAQs
 * @param documents
 * @returns
 */
export const updateDocumentFaqs =
  (documents: any[], documentId: string) =>
  async (dispatch: AppDispatchType) => {
    try {
      for await (const document of documents) {
        if (document.questions && document.questions.length > 0) {
          await service.createNewIntent({
            qna_id: document.qna_id,
            name: document.questions[0].text,
            original_question: document.questions[0].text,
            questions: document.questions,
            responses: await convertArrayToMarkdownFormat(document.responses),
            original_response: await convertDataToMarkdownFormat(
              document.responses[0].text,
            ),
            document_id: documentId,
            type: 3,
          })
        }
      }
      helperService.showToaster(
        t('Questions are added to the knowledge successfully.'),
        'success',
      )
      return Promise.resolve(true)
    } catch (e: any) {
      helperService.showToaster(
        'Something went wrong while updating responses.',
      )
      return Promise.reject()
    }
  }

export const getAllDocuments =
  (filters: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getAllDocuments(filters)
      return Promise.resolve(response.data.data)
    } catch (e: any) {
      // console.log('e', e);
      // helperService.showToaster('Something went wrong while updating responses.');
      return Promise.reject({
        documents: [],
        pagination: {
          page: 1,
          page_size: 1,
          total_items: 1,
          total_pages: 1,
        },
      })
    }
  }

export const getAllDocumentsNewFlow =
  (
    filters: any,
    hasTopLevelApplied: boolean = false,
    isArchived: boolean = false,
  ) =>
  async (dispatch: AppDispatchType) => {
    try {
      // const response = await service.getAllDocuments(filters);
      const response = await service.getAllDocumentsNewFlow(
        filters,
        hasTopLevelApplied,
        isArchived,
      )
      return Promise.resolve(response.data.data)
    } catch (e: any) {
      // console.log('e', e);
      // helperService.showToaster('Something went wrong while updating responses.');
      return Promise.reject({
        documents: [],
        pagination: {
          page: 1,
          page_size: 1,
          total_items: 1,
          total_pages: 1,
        },
      })
    }
  }

export const getDocumentFromID =
  (documentId: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getDocumentFromID(documentId)
      return Promise.resolve(response.data.data.document)
    } catch (e: any) {
      return Promise.reject(null)
    }
  }

export const addTagsToDocument =
  (documentId: any, data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.addTagsToDocument(documentId, data)
      return Promise.resolve(response.data.data.document)
    } catch (e: any) {
      return Promise.reject(null)
    }
  }

export const updateDocumentSettings =
  (documentId: any, settings: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.updateDocumentSettings(
        documentId,
        settings,
      )
      helperService.showToaster(
        t('Document has been successfully updated.'),
        'success',
      )
      return Promise.resolve(response.data.data.document)
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while updating document.'),
      )
      return Promise.reject(null)
    }
  }

export const generateDocumentsTags =
  (documents: string[]) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.generateDocumentsTags(documents)
      return Promise.resolve(response.data.data.tagged_documents)
    } catch (e: any) {
      helperService.showToaster('Something went wrong while generating tags.')
      return Promise.reject(null)
    }
  }

export const suggestDocumentsTags =
  (documentIds: string[], tags: string[]) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.suggestDocumentsTags(documentIds, tags)
      return Promise.resolve(response.data.data.tagged_documents)
    } catch (e: any) {
      return Promise.reject(null)
    }
  }

export const deleteDocuments =
  (
    documentIds: string[],
    deleteIntents: boolean,
    isArchived: boolean = false,
    isArchiving: boolean = false,
  ) =>
  async (dispatch: AppDispatchType) => {
    // console.log('documentIds',documentIds)
    try {
      for await (const documentId of documentIds) {
        if (!isArchived && !isArchiving) {
          await service.deleteDocumentSectionsById(documentId)
        }
        await service.deleteDocumentById(documentId, deleteIntents, isArchiving)
      }
      return Promise.resolve(true)
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while deleting documents.'),
      )
      return Promise.reject(null)
    }
  }

export const restoreDocuments =
  (documentIds: string[]) => async (dispatch: AppDispatchType) => {
    try {
      for await (const documentId of documentIds) {
        await service.restoreDocumentById(documentId)
      }
      return Promise.resolve(true)
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while restoring documents.'),
      )
      return Promise.reject(null)
    }
  }

export const updateBulkDocumentSettings =
  (documentIds: string[], settings: any) =>
  async (dispatch: AppDispatchType) => {
    try {
      for await (const documentId of documentIds) {
        await service.updateDocumentSettings(documentId, settings)
      }
      helperService.showToaster(
        t('Document has been successfully updated.'),
        'success',
      )
      return Promise.resolve(true)
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while updating documents.'),
      )
      return Promise.reject(null)
    }
  }

export const getGeneratedContent =
  (data: {
    organisation_id: string
    _id: string
    keyword: string
    preceed: number
    succeed: number
  }) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getGeneratedContent(data)
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      return Promise.reject(null)
    }
  }

export const getTextBlock =
  (document_id: string, text_block_id: string) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getTextBlock(document_id, text_block_id)
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      return Promise.reject(null)
    }
  }

export const getMatchedAndUnmatchedQuestions =
  (filters: any) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getMatchedAndUnmatchedQuestions(filters)
      return Promise.resolve(reponse.data.data.report_data)
    } catch (e: any) {
      return Promise.reject([])
    }
  }

export const getReportsUnmatchedQuestions =
  (filters: any) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await dashboardService.getReportsUnmatchedQuestions(
        filters,
      )
      return Promise.resolve(reponse.data.data.report_data)
    } catch (e: any) {
      return Promise.reject([])
    }
  }

export const getReportsAnswersFromOutsideSource =
  (filters: any) => async (dispatch: AppDispatchType) => {
    try {
      const response =
        await dashboardService.getReportsAnswersFromOutsideSource(filters)
      return Promise.resolve(response.data.data.report_data)
    } catch (error: any) {
      return Promise.reject([])
    }
  }

export const getLearnerAttendance =
  (filters: any) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getLearnerAttendance(filters)
      return Promise.resolve(reponse.data.data.users)
    } catch (e: any) {
      return Promise.reject([])
    }
  }

export const studentTopicsReport =
  (filters: any) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.studentTopicsReport(filters)
      return Promise.resolve(reponse.data.data.student_topics)
    } catch (e: any) {
      return Promise.reject([])
    }
  }

export const getConversationsRecords =
  (userId: string) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getConversationsRecords(userId)
      return Promise.resolve(reponse.data.data.conversation_logs)
    } catch (e: any) {
      return Promise.reject([])
    }
  }

export const getAgentSettings = () => async (dispatch: AppDispatchType) => {
  try {
    const reponse = await service.getAgentSettings()
    return Promise.resolve(reponse.data)
  } catch (e: any) {
    return Promise.resolve(e.response.data)
  }
}

// New flow
export const getSectionsBasedOnDocumentId =
  (documents: any) => async (dispatch: AppDispatchType) => {
    const obj: any = []
    try {
      for (let index = 0; index < documents.length; index++) {
        const response = await service.getSectionsBasedOnDocumentId(
          documents[index],
        )
        obj.push(
          response.data.data.document_sections.map((section: any) => {
            return {
              ...section,
              label: section?.name,
              name: section?.name,
              value: section._id,
              _id: section._id,
              id: section._id,
              from_page: section.from_page,
              to_page: section.to_page,
            }
          }),
        )
      }
      return Promise.resolve(obj.length > 0 ? obj[0] : [])
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while generating sections.'),
      )
      return Promise.resolve({})
    }
  }

export const generateQuestionsAndAnswersBasedOnDocument =
  (userDocuments: any, document: any[], sectionResponse: any = null) =>
  async (dispatch: AppDispatchType) => {
    dispatch({
      type: type.INIT_GENERATE_ANSWERS_SCREEN,
      payload: {
        keyWordsLoading: true,
      },
    })
    for (let index = 0; index < document.length; index++) {
      try {
        const hasDocument = userDocuments.find(
          (userDocument: any) => userDocument._id === document[index],
        )
        let documentResponse
        if (!hasDocument) {
          documentResponse = await service.getDocumentFromID(document[index])
          await dispatch({
            type: type.STORE_DOCUMENTS,
            payload: documentResponse.data.data.document,
          })
        }

        const generatedQnAs = await service.getQAndAsBasedOnDocument(
          document[index],
          0,
          10,
          sectionResponse,
        )
        await dispatch({
          type: type.INIT_STORE_QUESTIONS_FOR_KEYWORDS,
          payload: {
            documentId: document[index],
            loading: true,
          },
        })
        dispatch({
          type: type.STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS,
          payload: {
            qas: _.orderBy(generatedQnAs.data.data.qnas, 'sort_order', 'asc'),
            retryExecution: generatedQnAs.data.data.qnas.length === 0,
            last_offset: generatedQnAs.data.data.last_offset,
            qasFetchError: false,
            documentId: document[index],
          },
        })
        dispatch({
          type: type.INIT_STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS,
          payload: {
            documentId: document[index],
            loading: false,
          },
        })
      } catch (error: any) {
        dispatch({
          type: type.STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS,
          payload: {
            qas: [],
            retryExecution: false,
            last_offset: 0,
            qasFetchError: true,
            documentId: document[index],
          },
        })
        helperService.showToaster(
          t('Something went wrong while fetching questions and answers.'),
        )
      }
    }
  }

export const loadMoreQuestionsAndAnswersBasedOnDocument =
  (
    document: any,
    offset: number = 0,
    limit: number = 10,
    section: any = null,
  ) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getQAndAsBasedOnDocument(
        document._id,
        offset,
        limit,
        section,
      )
      return Promise.resolve({
        qas: response.data.data.qnas,
        last_offset: response.data.data.last_offset,
        qasFetchError: false,
        documentId: document._id,
      })
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while loading more questions.'),
      )
      return Promise.reject({
        qas: [],
        last_offset: 0,
        documentId: document._id,
        qasFetchError: true,
      })
    }
  }

export const updateQnasSortOrder =
  (documentId: string, data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.updateQnasSortOrder(documentId, data)
      return Promise.resolve(response.data.data)
    } catch (e: any) {
      return Promise.resolve(null)
    }
  }

export const generateCustomQuestionAnswer =
  (documentId: string, question: string) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.generateCustomQuestionAnswer(
        documentId,
        question,
      )
      return Promise.resolve(response.data.data.qna)
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while fetching answer.'),
      )
      return Promise.resolve(null)
    }
  }

export const deleteQuestionFromDocument =
  (documentId: string, qna_id: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.deleteQuestionFromDocument(
        documentId,
        qna_id,
      )
      return Promise.resolve(response.data.data.qna)
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while fetching answer.'),
      )
      return Promise.resolve(null)
    }
  }

export const updateQuestionFromDocument =
  (
    documentId: string,
    qna_id: string,
    data: {
      question: string
      answer: string
    },
  ) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.updateQuestionFromDocument(
        documentId,
        qna_id,
        data,
      )
      return Promise.resolve(response.data.data.qna)
    } catch (e: any) {
      helperService.showToaster(t('Please check your inputs.'))
      return Promise.resolve(null)
    }
  }

export const saveQuestionsAndAnswers =
  (
    updatedQuestions: any[],
    shouldCreateButtons: boolean,
    createIntentWithGroup: boolean = false,
    groupId: string | null = null,
    assessemntQueType: any[] = [],
    genAssessmentQna: boolean = false,
  ) =>
  async (dispatch: AppDispatchType) => {
    try {
      let intentsArray: string[] = []
      let res: any = {}
      for (const document of updatedQuestions) {
        let buttonData = {}
        for await (const qas of document.qas.reverse()) {
          const intentResponse: any = await service.createNewIntent({
            name: qas.question,
            qna_id: qas._id,
            questions: [{ text: qas.question }],
            responses: await convertArrayToMarkdownFormat([
              { text: qas.answer },
            ]),
            document_id: document._id,
            type: 3,
            buttons:
              shouldCreateButtons && Object.keys(buttonData).length
                ? [buttonData]
                : [],
          })

          if (createIntentWithGroup && groupId) {
            await service.addEntityToKnowledgeGroup(groupId, 'intent', [
              intentResponse.data.data.intent._id,
            ])
          }

          if (
            shouldCreateButtons &&
            intentResponse &&
            intentResponse.data.data &&
            intentResponse.data.data.intent
          ) {
            buttonData = {
              group_ids: null,
              label: intentResponse.data.data.intent.name,
              name: intentResponse.data.data.intent.name,
              reference: null,
              reference_id: intentResponse.data.data.intent._id,
              type: 'intent',
            }
          }
          if (intentResponse.data.data.intent._id) {
            intentsArray.push(intentResponse.data.data.intent._id)
          }
        }
      }

      if (intentsArray.length && genAssessmentQna) {
        const type = assessemntQueType.map(e => e.type)
        res = service.generateAssessmentquiz({
          intent_ids: intentsArray,
          qna_types: type,
          creates_flow: shouldCreateButtons,
        })
      }

      helperService.showToaster(
        t('Knowledge has been successfully created.'),
        'success',
      )
      return genAssessmentQna ? Promise.resolve(res) : Promise.resolve(true)
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while fetching answer.'),
      )
      return Promise.reject(false)
    }
  }

export const getSuggestedKnowledge =
  (filters: any) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getSuggestedKnowledge(filters)
      return Promise.resolve(reponse.data.data.report_data)
    } catch (e: any) {
      return Promise.reject([])
    }
  }

export const getAdminsBasedOnRoles =
  (filters: any, withRoles: boolean = true) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getAdminsBasedOnRoles(filters, withRoles)
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      return Promise.reject([])
    }
  }

export const deleteAdminsFromRoles =
  (agentId: string, deletedAgentId: any, role: string) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.deleteAdminsFromRoles(
        agentId,
        deletedAgentId,
        role,
      )
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      helperService.showToaster(
        role === 'superadmin'
          ? t("You're not allowed to delete superadmin.")
          : t('Something went wrong while removing admin.'),
      )
      return Promise.reject([])
    }
  }

export const deleteLearnerToClasses =
  (agentId: string, deletedAgentId: string, role: string) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.deleteLearnerToClasses(
        agentId,
        deletedAgentId,
        role,
      )
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      helperService.showToaster(
        role === 'superadmin'
          ? t("You're not allowed to delete superadmin.")
          : t('Something went wrong while removing admin.'),
      )
      return Promise.reject([])
    }
  }

export const addUserAsAdmins =
  (agentId: string, deletedAgentId: string) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.addUserAsAdmins(agentId, deletedAgentId)
      return Promise.resolve(response.data.data)
    } catch (e: any) {
      let errorMsg = getResponseErrorMessage(
        e?.response,
        t('Something went wrong while adding user.'),
      )
      helperService.showToaster(errorMsg)
      return Promise.reject([])
    }
  }

export const addAdminsToClass =
  (classId: string, admins: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.addAdminsToClass(classId, admins)
      return Promise.resolve(response.data.data)
    } catch (e: any) {
      let errorMsg = getResponseErrorMessage(
        e?.response,
        t('Something went wrong while adding user.'),
      )
      helperService.showToaster(errorMsg)
      return Promise.reject([])
    }
  }

export const deleteAdminsFromClasses =
  (classId: string, admins: string) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.deleteAdminsFromClasses(classId, admins)
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      let errorMsg = getResponseErrorMessage(
        e?.response,
        t('Something went wrong while adding user.'),
      )
      helperService.showToaster(errorMsg)
      return Promise.reject([])
    }
  }

export const addRoleUserToAdmins =
  (
    agentId: string,
    deletedAgentId: string,
    role: string,
    isSuperadmin: boolean,
  ) =>
  async (dispatch: AppDispatchType) => {
    if (isSuperadmin) {
      helperService.showToaster(
        t('You are not allowed to update permissions for super admin.'),
        'error',
      )
      return
    }
    try {
      const reponse = await service.addRoleUserToAdmins(
        agentId,
        deletedAgentId,
        role,
      )
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      let errorMsg = getResponseErrorMessage(
        e?.response,
        t('Something went wrong while adding user.'),
      )
      helperService.showToaster(errorMsg)
      return Promise.reject([])
    }
  }

export const addLearnerToClasses =
  (agentId: string, deletedAgentId: string, role: string) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.addLearnerToClasses(
        agentId,
        deletedAgentId,
        role,
      )
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      let errorMsg = getResponseErrorMessage(
        e?.response,
        t('Something went wrong while adding user.'),
      )
      helperService.showToaster(errorMsg)
      return Promise.reject([])
    }
  }

export const updateAdminUser =
  (data: any, id: string) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.updateAdminUser(data, id)
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      let errorMsg = getResponseErrorMessage(
        e?.response,
        t('Something went wrong while updating details.'),
      )
      helperService.showToaster(errorMsg)
      return Promise.reject([])
    }
  }

export const addAdminUser =
  (data: any) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.addAdminUser(data)
      return Promise.resolve(reponse.data.data.user)
    } catch (e: any) {
      let errorMsg = getResponseErrorMessage(
        e?.response,
        t('Something went wrong while adding user.'),
      )
      helperService.showToaster(errorMsg)
      return Promise.reject([])
    }
  }

export const resendInvite =
  (id: string, notification: boolean = true) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.resendInvite(id)
      if (notification) {
        helperService.showToaster('Invite successfully sent.', 'success')
      }
      return Promise.resolve(reponse.data)
    } catch (e: any) {
      let errorMsg = getResponseErrorMessage(
        e?.response,
        t('Something went wrong while resending invite.'),
      )
      helperService.showToaster(errorMsg)
      return Promise.reject([])
    }
  }

export const sendForgotPassword =
  (user_id: string, sends_email: boolean = false) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.sendForgotPassword(user_id, sends_email)
      if (sends_email) {
        helperService.showToaster(
          'Forgot password email successfully sent.',
          'success',
        )
      }
      return Promise.resolve(reponse.data)
    } catch (e: any) {
      let errorMsg = getResponseErrorMessage(
        e?.response,
        t('Something went wrong while requesting forgot password URL.'),
      )
      helperService.showToaster(errorMsg)
      return Promise.reject([])
    }
  }

export const addDocumentSectionsForDocuments =
  (documents: any, sectionsData: any, showToast: boolean = true) =>
  async (dispatch: AppDispatchType) => {
    try {
      let temp: any = {}
      for await (const document of documents) {
        temp[document] = {
          ...temp[document],
          sections: [],
        }
        if (document) {
          let data = sectionsData[document].filter(
            (section: any) => section.name.trim() !== '',
          )
          let payload = {
            document_sections: data,
          }
          const sectionResponse: any = await service.addDocumentSections(
            document,
            payload,
          )
          temp[document].sections = sectionResponse.data.data.document_sections
        }
      }
      if (showToast) {
        helperService.showToaster(t('Documents saved successfully.'), 'success')
      }
      return Promise.resolve(temp)
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while saving the documents.'),
      )
      return Promise.reject()
    }
  }

export const updateDocumentSections =
  (documents: any, sectionsData: any, showToast: boolean = true) =>
  async (dispatch: AppDispatchType) => {
    try {
      for await (const document of documents) {
        if (document) {
          let data = sectionsData[document].filter(
            (section: any) => section.name.trim() !== '',
          )
          let payload = {
            document_sections: data,
          }
          await service.updateDocumentSections(document, payload)
        }
      }
      showToast &&
        helperService.showToaster(t('Documents saved successfully.'), 'success')
      return Promise.resolve(true)
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while saving the documents.'),
      )
      return Promise.reject()
    }
  }

export const getDocumentPreviewUrl =
  (document: any, source: any = undefined, getOriginal: boolean = false) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getDocumentPreviewUrl(
        document,
        source,
        getOriginal,
      )
      return Promise.resolve(response.data)
    } catch (e: any) {
      return Promise.reject()
    }
  }

export const bulkAssignCategories =
  (
    knowledgeIds: string[],
    data: {
      subtopic_id?: string
      topic_id?: string
      topic_name?: string
    },
  ) =>
  async (dispatch: AppDispatchType) => {
    try {
      for (let index = 0; index < knowledgeIds.length; index++) {
        dispatch({
          type: type.INIT_KNOWLEDGE_BY_ID,
        })
        const response = await service.updateKnowledgeById(
          knowledgeIds[index],
          data,
        )
        dispatch({
          type: type.UPDATE_KNOWLEDGE_BY_ID_SUCCESS,
          payload: {
            ...response.data.data.intent,
            topic_name: data.topic_name,
          },
        })
      }
      helperService.showToaster(t('Updated successfully.'), 'success')
    } catch (error: any) {
      helperService.showToaster(
        t('Something went wrong while updating knowledge.'),
      )
      dispatch({
        type: type.UPDATE_KNOWLEDGE_BY_ID_ERROR,
      })
    }
  }

export const accessRightsData =
  (payload: object) => (dispatch: AppDispatchType) => {
    dispatch({
      type: type.MANAGE_ACCESS_RIGHTS_DATA,
      payload: payload,
    })
  }

export const getUnansweredQuestionCount =
  () => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getUnansweredQuestionCount()
      return Promise.resolve(response.data.data.unanswered_question_count)
    } catch (error: any) {
      return Promise.reject(0)
    }
  }

// LEVELS
export const getAllLevels = () => async (dispatch: AppDispatchType) => {
  try {
    const response = await service.getAllKnowledgeGroups()
    // transformNestedLevels
    return Promise.resolve(response.data.data.groups)
  } catch (error: any) {
    return Promise.reject([])
  }
}

export const getAllModulesFromYear =
  (id: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getAllModulesFromYear(id)
      // transformNestedLevels
      return Promise.resolve(
        transformNestedModules(
          transformNestedLevels(response.data.data.groups),
        ),
      )
    } catch (error: any) {
      return Promise.reject([])
    }
  }

export const getAllModulesFromYearForSelction = async (id: string) => {
  try {
    const response = await service.getAllModulesFromYear(id)
    const transformNestedValues = transformNestedLevels(
      response.data.data.groups,
    )
    const transformNestModules = transformNestedModules(transformNestedValues)
    const formattedModule = setupModuleFormat(transformNestModules)
    return Promise.resolve(formattedModule)
  } catch (error: any) {
    return Promise.reject([])
  }
}

export const getAllModulesSubgroups =
  () => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getAllKnowledgeGroupsSubGroups()
      // transformNestedLevels
      return Promise.resolve(
        transformNestedModules(
          transformNestedLevels(response.data.data.groups),
        ),
      )
    } catch (error: any) {
      return Promise.reject([])
    }
  }

export const getTreeModulesFromYear =
  (id: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getAllModulesFromYear(id)
      // transformNestedLevels
      return Promise.resolve(transformNestedLevels(response.data.data.groups))
    } catch (error: any) {
      return Promise.reject([])
    }
  }

export const getTreeModulesFromYearSub =
  () => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getAllKnowledgeGroupsSubGroups()
      // transformNestedLevels
      return Promise.resolve(transformNestedLevels(response.data.data.groups))
    } catch (error: any) {
      return Promise.reject([])
    }
  }

export const createKnowledgeGroup =
  (data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.createKnowledgeGroup(data)
      // transformNestedLevels
      return Promise.resolve(response.data.data)
    } catch (error: any) {
      helperService.showToaster(
        t('Something went wrong while creating knowledge group.'),
      )
      return Promise.reject([])
    }
  }

export const updateKnowledgeGroup =
  (data: any, knowledge_group_id: string) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.updateKnowledgeGroup(
        data,
        knowledge_group_id,
      )
      // transformNestedLevels
      return Promise.resolve(response.data.data)
    } catch (error: any) {
      let error_message =
        error.response.data?.status?.message != null
          ? error.response.data.status.message
          : t('Something went wrong while deleting knowledge group.')
      helperService.showToaster(error_message)
      return Promise.reject([])
    }
  }

export const deleteKnowledgeGroup =
  (knowledge_group_id: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.deleteKnowledgeGroup(knowledge_group_id)
      // transformNestedLevels
      return Promise.resolve(response.data.data)
    } catch (error: any) {
      let error_message =
        error.response.data?.status?.message != null
          ? error.response.data.status.message
          : t('Something went wrong while deleting knowledge group.')
      helperService.showToaster(error_message)
      return Promise.reject([])
    }
  }

export const addEntityToKnowledgeGroup =
  (
    knowledge_group_id: string,
    entity_type: string,
    entity_ids: string[],
    hideToaster: boolean = false,
    isRolePlay: boolean = false,
  ) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.addEntityToKnowledgeGroup(
        knowledge_group_id,
        entity_type,
        entity_ids,
      )
      {
        !hideToaster &&
          helperService.showToaster(
            `${
              entity_type === 'document'
                ? 'Document'
                : entity_type === 'quiz'
                ? isRolePlay
                  ? 'Role Play'
                  : 'Quiz'
                : 'Intent'
            } ${t('has been successfully added to a knowledge group.')}`,
            'success',
          )
      }
      return Promise.resolve(response.data.data)
    } catch (error: any) {
      helperService.showToaster(
        t('Something went wrong while deleting knowledge group.'),
      )
      return Promise.reject([])
    }
  }

export const getCourseClasses = () => async (dispatch: AppDispatchType) => {
  try {
    const response = await service.getCourseClasses()
    return Promise.resolve(response.data.data.classes)
  } catch (error: any) {
    // helperService.showToaster(
    //   "Something went wrong while deleting knowledge group."
    // );
    return Promise.reject([])
  }
}

export const getUserGroups =
  (doNotAllowSystemGroup: boolean = false) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getUserGroups(doNotAllowSystemGroup)

      let groups = []
      groups = response.data.data.groups.map((obj: any) => {
        return {
          ...obj,
          labelForSorting: obj.name.toLowerCase(),
        }
      })
      return Promise.resolve(
        _.orderBy([...groups], ['labelForSorting'], ['asc']),
      )
    } catch (error: any) {
      // helperService.showToaster(
      //   "Something went wrong while deleting knowledge group."
      // );
      return Promise.reject([])
    }
  }

export const getUserGroupById =
  (id: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getUserGroupById(id)
      return Promise.resolve(response.data.data.group)
    } catch (error: any) {
      // helperService.showToaster(
      //   "Something went wrong while deleting knowledge group."
      // );
      return Promise.reject([])
    }
  }

export const updateUserGroupNameById =
  (id: string, data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.updateUserGroupNameById(id, data)
      return Promise.resolve(response.data.data.group)
    } catch (error: any) {
      // helperService.showToaster(
      //   "Something went wrong while deleting knowledge group."
      // );
      return Promise.reject([])
    }
  }

export const getCourseClassesById =
  (id: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getCourseClassesById(id)
      return Promise.resolve(response.data.data.class)
    } catch (error: any) {
      // helperService.showToaster(
      //   "Something went wrong while deleting knowledge group."
      // );
      return Promise.reject([])
    }
  }

export const getCourseLmsById =
  (id: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.getCourseLmsById(id)
      return Promise.resolve(response.data.data.lmscourse)
    } catch (error: any) {
      // helperService.showToaster(
      //   "Something went wrong while deleting knowledge group."
      // );
      return Promise.reject([])
    }
  }

export const updateUserGroupById =
  (id: string, data: string[], accessAllKnowledge: boolean = false) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.updateUserGroupById(
        id,
        data,
        accessAllKnowledge,
      )
      helperService.showToaster(t('Updated successfully.'), 'success')
      return Promise.resolve(response.data.data.group)
    } catch (error: any) {
      // helperService.showToaster(
      //   "Something went wrong while deleting knowledge group."
      // );
      return Promise.reject([])
    }
  }

export const updateCourseClassesById =
  (
    id: string,
    data: string[],
    accessAllKnowledge: boolean = false,
    showPromt: boolean = true,
  ) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.updateCourseClassesById(
        id,
        data,
        accessAllKnowledge,
      )
      if (showPromt) {
        helperService.showToaster(t('Updated successfully.'), 'success')
      }
      return Promise.resolve(response.data.data.class)
    } catch (error: any) {
      // helperService.showToaster(
      //   "Something went wrong while deleting knowledge group."
      // );
      return Promise.reject([])
    }
  }

export const bulkAssignModules =
  (
    groupId: string,
    entity_ids: string[],
    entity_type: string = 'intent',
    showToaster: boolean = true,
  ) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.addEntityToKnowledgeGroup(
        groupId,
        entity_type ? entity_type : 'intent',
        entity_ids,
      )
      showToaster &&
        helperService.showToaster(t('Updated successfully.'), 'success')
    } catch (error: any) {
      helperService.showToaster(
        t('Something went wrong while updating knowledge.'),
      )
    }
  }

export const addClass = (data: any) => async (dispatch: AppDispatchType) => {
  try {
    const response = await service.addClass(data)
    helperService.showToaster(t('Added successfully.'), 'success')
  } catch (error: any) {
    helperService.showToaster(t('Something went wrong while adding class.'))
  }
}

export const deleteClass =
  (classId: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.deleteClass(classId)
      return Promise.resolve()
    } catch (error: any) {
      helperService.showToaster(t('Something went wrong while deleting class.'))
    }
  }

export const duplicateClass =
  (data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.duplicateClass(data)
      helperService.showToaster(
        t('The class has been duplicated successfully!'),
        'success',
      )
      return Promise.resolve()
    } catch (error: any) {
      helperService.showToaster(
        t(
          "There's an error encountered during the duplication process, please contact the administrator.",
        ),
      )
    }
  }

export const duplicateLearnerGroup =
  (data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.duplicateLaernerGroup(data)
      helperService.showToaster(
        t('The learner group has been duplicated successfully!'),
        'success',
      )
      return Promise.resolve()
    } catch (error: any) {
      helperService.showToaster(
        t(
          "There's an error encountered during the duplication process, please contact the administrator.",
        ),
      )
    }
  }

export const linkACourseToClass =
  (data: any, classId: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.linkACourseToClass(data, classId)
      return Promise.resolve(response)
    } catch (e: any) {
      let errorMsg = getResponseErrorMessage(
        e?.response,
        t('Something went wrong while adding user.'),
      )
      helperService.showToaster(errorMsg)
    }
  }

export const unlinkACourseToClass =
  (classId: string) => async (dispatch: AppDispatchType) => {
    try {
      await service.unlinkACourseToClass(classId)
      return Promise.resolve()
    } catch (error: any) {
      helperService.showToaster(
        t('Something went wrong while linking course to class.'),
      )
    }
  }

export const updateClassDetailById =
  (classId: string, data: any) => async (dispatch: AppDispatchType) => {
    try {
      await service.updateClassDetailById(classId, data)
      return Promise.resolve()
    } catch (error: any) {
      helperService.showToaster(
        t('Something went wrong while updating class detail.'),
      )
    }
  }

export const addLearnerGroup =
  (name: string) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.addLearnerGroup(name)
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      helperService.showToaster(t('Something went wrong while creating group.'))
      return Promise.reject([])
    }
  }

export const deleteLearnerGroup =
  (name: string) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.deleteLearnerGroup(name)
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      helperService.showToaster(t('Something went wrong while creating group.'))
      return Promise.reject([])
    }
  }

export const getLearningUserDetail =
  (name: string) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getLearningUserDetail(name)
      return Promise.resolve(reponse.data.data.user)
    } catch (e: any) {
      helperService.showToaster(
        t('Something went wrong while fetching details.'),
      )
      return Promise.reject([])
    }
  }

export const addUsersAndClassesToLearnerGroup =
  (groupId: string, data: any) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.addUsersAndClassesToLearnerGroup(
        groupId,
        data,
      )
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      helperService.showToaster(
        t(
          'Something went wrong while adding classes and user to selected group.',
        ),
      )
      return Promise.reject([])
    }
  }

export const deleteUsersAndClassesToLearnerGroup =
  (groupId: string, data: any) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.deleteUsersAndClassesToLearnerGroup(
        groupId,
        data,
      )
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      helperService.showToaster(
        t(
          'Something went wrong while adding classes and user to selected group.',
        ),
      )
      return Promise.reject([])
    }
  }

export const uploadBulkLearners =
  (data: any, importType: string, silent: boolean = false) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.uploadBulkLearners(data, importType, silent)
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      helperService.showToaster(
        e.response &&
          e.response.data &&
          e.response.data.status &&
          e.response.data.status.message
          ? e.response.data.status.message
          : t('Something went wrong while creating users.'),
      )
      return Promise.reject([])
    }
  }

export const sendDocToSumRequest =
  (data: any, silent: boolean = false) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.sendDocToSumRequest(data)
      return Promise.resolve(reponse.data.data.docsum_request)
    } catch (e: any) {
      helperService.showToaster(
        t(
          'Something went wrong while sending documents for summarisation. Please ensure that total page count does not exceed 20 pages',
        ),
      )
      return Promise.reject(null)
    }
  }

export const sendBuildDocToSumRequest =
  (data: any, silent: boolean = false, isSmartQuiz: boolean = false) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.sendBuildDocToSumRequest(data, isSmartQuiz)
      return Promise.resolve(reponse.data.data.docsum_request)
    } catch (e: any) {
      helperService.showToaster(
        t(
          'Something went wrong while sending documents for summarisation. Please ensure that total page count does not exceed 20 pages',
        ),
      )
      return Promise.reject(null)
    }
  }

export const getDocumentsValidations =
  (data: any, silent: boolean = false) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getDocumentsValidations(data)
      return Promise.resolve(reponse.data.data.documents)
    } catch (e: any) {
      // helperService.showToaster(t("Something went wrong while sending documents for summarisation."));
      return Promise.reject([])
    }
  }

export const getDocumentsValidationsV3 =
  (data: any, silent: boolean = false) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getDocumentsValidationsV3(data)
      return Promise.resolve(reponse.data.data.documents)
    } catch (e: any) {
      // helperService.showToaster(t("Something went wrong while sending documents for summarisation."));
      return Promise.reject([])
    }
  }

export const getDocumentsTextBlocks =
  (documentId: any, source: any) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getDocumentsTextBlocks(documentId, source)
      return Promise.resolve(reponse.data.data.text_blocks)
    } catch (e: any) {
      // helperService.showToaster(t("Something went wrong while sending documents for summarisation."));
      return Promise.reject([])
    }
  }

export const docSearchAddToIndex =
  (documentIds: string[]) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.docSearchAddToIndex(documentIds)
      return Promise.resolve(reponse.data.data.text_blocks)
    } catch (e: any) {
      // helperService.showToaster(t("Something went wrong while sending documents for summarisation."));
      return Promise.reject([])
    }
  }

export const generateQuestionAnswerBasedonNumberOfInput =
  (document_id: string, max_qnas: number, source: any, questionType?: string) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await service.generateQuestionAnswerBasedonNumberOfInput(
        document_id,
        max_qnas,
        source,
        questionType,
      )
      return Promise.resolve(
        orderBy(response.data.data.qnas, ['sort_order'], ['asc']),
      )
    } catch (e: any) {
      return Promise.reject([])
    }
  }

export const validateDocuments =
  (documents: any, file_name: string, eventCallback: any) =>
  async (dispatch: AppDispatchType) => {
    try {
      const response = await uploadService.validateDocuments(
        documents,
        file_name,
        (event: any) => eventCallback(event),
      )
      return { data: response.data.data, file_name, isError: false }
    } catch (error: any) {
      return {
        data:
          error && error.response && error.response.data
            ? error.response.data
            : error.message,
        file_name,
        isError: true,
      }
    }
  }

export const exportQuestions =
  (selectedNodes: any, filters: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.exportQuestions(selectedNodes, filters)
      return Promise.resolve(response.data)
    } catch (error: any) {
      return Promise.reject(null)
    }
  }

export const exportLearnerAttendance =
  (filters: any, fileFormat: string) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.exportLearnerAttendance(
        filters,
        fileFormat,
      )

      return Promise.resolve(response.data)
    } catch (error: any) {
      return Promise.reject(null)
    }
  }

export const getDocumentProcess =
  (documentId: any) => async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getDocumentProcess(documentId)
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      // helperService.showToaster(t("Something went wrong while sending documents for summarisation."));
      return Promise.reject([])
    }
  }

export const getToolkitQuizQuestions =
  (documentId: any, data: any, source: any) =>
  async (dispatch: AppDispatchType) => {
    try {
      const reponse = await service.getToolkitQuizQuestions(
        documentId,
        data,
        source,
      )
      return Promise.resolve(reponse.data.data)
    } catch (e: any) {
      // helperService.showToaster(t("Something went wrong while sending documents for summarisation."));
      return Promise.reject([])
    }
  }

export const renameDocument =
  (documentId: string, name: string) =>
  async (dispatch: AppDispatchType, getState: any) => {
    try {
      const response = await service.renameDocument(documentId, name)
      helperService.showToaster(t('Document has been renamed'), 'success')
      return Promise.resolve(response.data.data.document)
    } catch (e: any) {
      return Promise.reject([])
    }
  }

export const exportQuizzesAndQuestions =
  (data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.exportQuizzesAndQuestions(data)
      return Promise.resolve(response.data)
    } catch (error: any) {
      return Promise.reject(null)
    }
  }

export const recentlyUsedDocuments =
  (agentId: string, documents: any, existingAgentDocuments: any) =>
  async (dispatch: AppDispatchType) => {
    try {
      let agentsDocs: any = {
        ...existingAgentDocuments,
      }
      let existingDocs =
        agentsDocs && agentsDocs.hasOwnProperty(agentId)
          ? agentsDocs[agentId]
          : []
      if (existingDocs.length && existingDocs[0].document_Id) {
        existingDocs = _.uniq([...documents])
      } else {
        existingDocs = _.uniq([...existingDocs, ...documents])
      }

      if (agentsDocs.hasOwnProperty(agentId)) {
        agentsDocs[agentId] = [...existingDocs]
        // await dispatch(
        //   forceUpdateOrganizationState({
        //     recentlyUsedDocuments: JSON.stringify(agentsDocs),
        //   })
        // );
      }
      return Promise.resolve(true)
    } catch (e: any) {
      return Promise.reject(e)
    }
  }

export const loadSampleDocuments =
  (data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.loadSampleDocuments(data)
      return Promise.resolve(response.data.data)
    } catch (error: any) {
      return Promise.reject(null)
    }
  }

export const exportSubmissions =
  (quizId: any, data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.exportSubmissions(quizId, data)
      return Promise.resolve(response.data)
    } catch (error: any) {
      return Promise.reject(null)
    }
  }

export const exportSubmissionsRolePlay =
  (quizId: any, data: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await service.exportSubmissionsRolePlay(quizId, data)
      return Promise.resolve(response.data)
    } catch (error: any) {
      return Promise.reject(null)
    }
  }

export const getBotResponses =
  (filters: any) => async (dispatch: AppDispatchType) => {
    try {
      const response = await dashboardService.getBotResponses(filters)
      return Promise.resolve(response.data.data.report_data)
    } catch (error: any) {
      return Promise.reject([])
    }
  }
