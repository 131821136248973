import cx from 'classnames'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image } from 'react-bootstrap'
import classNames from 'classnames'
import CommonService from '../../services/CommonService'
import google from './../../assets/images/Google-Official.svg'
import logoImg from './../../assets/images/Logo-Image.svg'
import microsoft from './../../assets/images/logos_microsoft-icon.svg'
import noodleFactory from './../../assets/images/NoodleFactory.svg'
import ChatImages from './ChatImages'
import { COUNTRY_OPTIONS } from './Constants'
import './styles.scss'
import { useHistory } from 'react-router'

const commonService = new CommonService()

export const EmailInput = (props: any) => {
  const { t } = useTranslation('translation')
  const history = useHistory()
  const { value, onChange, form, gotoInfo, isMobile } = props
  const [emailInvalid, setEmailInValid] = useState(false)
  const [alreadySign, setAlreadySign] = useState(false)

  const getLanguage = async () => {
    const res = await commonService.getRecommendedLanguage()
    if (res?.data?.data?.country) {
      const country = COUNTRY_OPTIONS.find(
        (c: any) => c.value === res?.data?.data?.country,
      )
      if (country) {
        form.setFieldValue('country', country.value)
      }
    }
  }

  useEffect(() => {
    getLanguage()
  }, [])

  const isEmailExisted = async () => {
    try {
      await commonService.isEmailExisted(value)
      setEmailInValid(false)
      gotoInfo()
    } catch (error) {
      setEmailInValid(true)
    }
  }

  return (
    <div
      className={cx({
        'd-flex': true,
        'flex-column': isMobile,
      })}
    >
      <div className="green-bg d-flex flex-1 flex-column align-items-center justify-content-center max-col">
        <div className="tw-flex tw-items-center top-logo tw-gap-1">
          <Image src={logoImg} />
          <Image src={noodleFactory} />
        </div>
        {alreadySign ? (
          <div className="content-wrapper w-100">
            <h1 className="tw-text-[32px] tw-font-extrabold tw-text-[#FF009F]">
              {t('Already have an account? Sign in')}
            </h1>
            <p className="tw-text-lg tw-font-bold mt-3 mb-3">
              {t('Choose your portal to continue')}
            </p>
            <div className="tw-flex tw-items-center tw-gap-2 tw-w-full tw-mx-7">
              <a
                href={(window as any).NEW_PORTAL_URI}
                className="tw-py-4 tw-px-8 tw-bg-[#001DE0] tw-rounded-xl tw-text-[#FBFBFF] hover:tw-text-[#FBFBFF] tw-font-bold tw-w-full tw-text-center tw-no-underline hover:tw-no-underline"
              >
                {t('Educator Portal')}
              </a>
              <a
                href={(window as any).CHATBOT_URL}
                className="tw-py-4 tw-px-8 tw-border tw-border-[#001DE0] tw-rounded-xl tw-text-[#001DE0] hover:tw-text-[#001DE0] tw-font-bold tw-w-full tw-text-center tw-no-underline hover:tw-no-underline"
              >
                {t('Leaner Portal')}
              </a>
            </div>
            <div className="tw-mt-3 text-center tw-font-semibold tw-text-lg tw-text-[#5F5E5E]">
              <p className="!tw-mb-0 tw-text-lg">
                {t('Have trouble accessing your account?')}
              </p>
              <div className="tw-flex tw-items-center tw-gap-1">
                <p className="!tw-mb-0 tw-text-lg">{t('Contact')}</p>
                <span className="tw-text-[#001DE0]">
                  {t('support@noodlefactory.ai')}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={cx({
              'content-wrapper w-100': true,
              mobile: isMobile,
            })}
          >
            <h1 className="tw-text-[32px] tw-font-extrabold tw-text-[#FF009F] tw-text-center">
              {t('Sign up to Noodle Factory')}
            </h1>
            <div className="email-wrapper">
              <div className="tw-py-2 tw-text-base tw-font-semibold">
                {t('Email')}
              </div>
              <input
                type="text"
                value={value}
                className={classNames({
                  '!tw-rounded-lg form-control h-auto w-100 pt-2': true,
                  'border-danger': form.errors.email || emailInvalid,
                })}
                onChange={(e: any) => {
                  onChange(e?.target?.value)
                }}
                placeholder="johndoe@example.com"
              />
              {form.errors.email && (
                <div className="pt-2 clr-red">{t(form.errors.email)}</div>
              )}
              {emailInvalid && (
                <div className="pt-2 clr-red">
                  {t('This email has already been registered')}
                </div>
              )}
            </div>
            <div className="email-wrapper !tw-mt-3">
              <button
                className="btn btn-primary w-100 tw-font-medium !tw-text-sm tw-py-4 py-lg-2"
                disabled={form.errors.email || !value}
                onClick={isEmailExisted}
              >
                {t('Sign Up')}
              </button>
            </div>
            <div className="tw-flex tw-items-center tw-gap-2 tw-w-1/2 tw-mt-5 tw-font-lg tw-font-semibold tw-text-[#5F5E5E]">
              <div className="tw-border tw-border-[#CAC5CA] tw-flex-1 tw-w-100"></div>
              <span className="!tw-m-0">{t('Or')}</span>
              <div className="tw-border tw-border-[#CAC5CA] tw-flex-1 tw-w-100"></div>
            </div>
            <div className="email-wrapper !tw-mt-4 tw-flex tw-flex-col tw-gap-2">
              <a
                href={`${
                  (window as any).REACT_APP_ONE_AUTH
                }/sociallogin/google/signup`}
                className="!tw-bg-[#FFFFFF] hover:tw-no-underline hover:tw-text-[#0E0748] tw-border tw-rounded-md w-100 tw-font-bold !tw-text-sm tw-py-3 tw-px-4 py-lg-2 tw-shadow-[0px_2px_4px_-2px_rgba(0,0,0,0.1)] tw-flex tw-gap-2 tw-items-center tw-justify-center"
              >
                {/* <button> */}
                <Image src={google} />
                {t('SIGN UP WITH GOOGLE')}
                {/* </button> */}
              </a>

              <a
                href={`${
                  (window as any).REACT_APP_ONE_AUTH
                }/sociallogin/azure/signup`}
                className="!tw-bg-[#FFFFFF] hover:tw-no-underline hover:tw-text-[#0E0748] tw-border tw-rounded-md w-100 tw-font-bold !tw-text-sm tw-py-3 tw-px-4 py-lg-2 tw-shadow-[0px_2px_4px_-2px_rgba(0,0,0,0.1)] tw-flex tw-gap-2 tw-items-center tw-justify-center"
              >
                <Image src={microsoft} />
                {t('SIGN UP WITH MICROSOFT')}
              </a>
            </div>
            <div className="email-wrapper !tw-mt-4 tw-items-center tw-gap-2 tw-text-center tw-text-lg tw-font-semibold">
              <span className="!tw-mt-0 !tw-mb-0 tw-text-md tw-text-[#5F5E5E]">
                {t('Already an account?')}
              </span>
              &nbsp;
              <span className="tw-text-[#001DE0]">
                <button
                  className="clr-blue p-0 ml-1"
                  // onClick={() => setAlreadySign(true)}
                  onClick={() => {
                    // console.log('window.location: ', window.location)
                    window.location.href =
                      'https://www.noodlefactory.ai/portal-login'
                    // window.location.href = `${window.location.origin}/portal-login`
                  }}
                >
                  {t('Sign in here')}
                </button>
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="tw-hidden md:tw-flex tw-flex-col tw-items-center tw-justify-center max-col">
        <ChatImages />
      </div>
    </div>
  )
}

export default EmailInput
