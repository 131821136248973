import React from "react";
import { useGlobalContext } from "../context/GlobalContext";
import Auth0Wrapper from "./Auth0Wrapper";
import OneAuthWrapper from "./OneAuthWrapper";

const ProtectedRoute: React.FunctionComponent<any> = ({
  component,
  ...args
}) => {
  const windowObj: any = window;

  const { userFlowExecution } = useGlobalContext();
  React.useEffect(() => {
    // if ( !userFlowExecution && !loadingFromApp && !authoLoading ) {
    //   history.push("/")
    // }
  }, [userFlowExecution]);

  // One AUTH wrapper
  if (windowObj.REACT_APP_SSO_PROVIDER === "oneauth") {
    return <OneAuthWrapper Component={component} {...args} />;
  } else {
    // AUTH0 wrapper
    return <Auth0Wrapper Component={component} {...args} />;
  }
};

export default ProtectedRoute;
